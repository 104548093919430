import React, { useState, useEffect, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';

import { InputLabel, FormControl, MenuItem, Select } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import Loading from '../../components/Loading';
import { useToast } from '../../hooks/toast';
import { StateModal, CityModal, NeighborhoodModal } from '../../components';
import { useAuth } from '../../hooks/auth';
import CitiesItem from './CitiesItem';

import {
  Container,
  DeliverymenList,
  ItemTitle,
  ContainerRow,
  ColorButton,
} from './styles';
import client from '../../services/graphqlClient';

const GetNeighborhoods = gql`
  query(
    $where: NeighborhoodWhereInput
    $after: NeighborhoodWhereUniqueInput
    $before: NeighborhoodWhereUniqueInput
    $first: Int
    $last: Int
  ) {
    neighborhoods(
      where: $where
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: { name: asc }
    ) {
      id
      name
      city {
        id
        name
        state {
          id
          name
        }
      }
      geolocation {
        latitude
        longitude
      }
    }
  }
`;

const AddNeighborhood = gql`
  mutation($data: NeighborhoodCreateInput!) {
    createOneNeighborhood(data: $data) {
      id
      name
    }
  }
`;

const GetCities = gql`
  query(
    $where: CityWhereInput
    $after: CityWhereUniqueInput
    $before: CityWhereUniqueInput
    $first: Int
    $last: Int
  ) {
    cities(
      where: $where
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: { name: asc }
    ) {
      id
      name
    }
  }
`;

interface Neighborhood {
  id: string;
  name: string;
  city: {
    id: string;
    name: string;
    state: {
      id: string;
      name: string;
    };
  };
  geolocation: {
    latitude: string;
    longitude: string;
  };
}

interface City {
  id: string;
  name: string;
}

interface UserInfo {
  role: string;
}

const Deliverymen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [neighborhoods, setNeighborhoods] = useState<Neighborhood[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [cityFilter, setCityFilter] = useState<string>('1');
  const [open, setOpen] = useState(false);
  const [openState, setOpenState] = useState(false);
  const [openCity, setOpenCity] = useState(false);

  const { addToast } = useToast();

  const { user } = useAuth();
  const [userInfo] = useState(user as UserInfo);

  const getNeighborhoods = useCallback(async (dataParams = {}) => {
    const { data } = await client.query({
      query: GetNeighborhoods,
      fetchPolicy: 'no-cache',
      variables: {
        ...dataParams,
      },
    });

    if (data.neighborhoods.length) {
      setNeighborhoods(data.neighborhoods);
    }
  }, []);

  const getCities = useCallback(async (dataParams = {}) => {
    const { data } = await client.query({
      query: GetCities,
      fetchPolicy: 'no-cache',
      variables: {
        ...dataParams,
      },
    });

    if (data.cities.length) {
      setCities(data.cities);
    }
  }, []);

  const createNeighborhood = useCallback(
    async (name, cityId, latitude, longitude) => {
      try {
        await client.mutate({
          mutation: AddNeighborhood,
          variables: {
            data: {
              name,
              city: {
                connect: {
                  id: cityId,
                },
              },
              geolocation: {
                create: {
                  latitude,
                  longitude,
                },
              },
            },
          },
        });
        await getNeighborhoods({ where: { cityId: { equals: cityId } } });
        setOpen(false);
        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ops, o bairro não pode ser cadastrado',
        });
      }
    },
    [getNeighborhoods, addToast],
  );

  useEffect(() => {
    setLoading(false);
    getNeighborhoods({ where: { cityId: { equals: 1 } } });
    getCities();
  }, [getNeighborhoods, getCities]);

  return (
    <>
      {userInfo.role === 'DEFAULT' ? (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      ) : (
        <Container>
          {loading ? (
            <Loading />
          ) : (
            <>
              <ContainerRow style={{ width: '50%' }}>
                <ColorButton
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  style={{ height: 50, marginRight: 10, flex: 1 }}
                  onClick={() => setOpenState(true)}
                >
                  Criar estado
                </ColorButton>
                <ColorButton
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  style={{ height: 50, marginRight: 10, flex: 1 }}
                  onClick={() => {
                    setOpenCity(true);
                  }}
                >
                  Criar cidade
                </ColorButton>
                <ColorButton
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  style={{ height: 50, flex: 1 }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Criar bairro
                </ColorButton>
              </ContainerRow>

              <ContainerRow style={{ marginTop: 20 }}>
                <FormControl variant="outlined" style={{ flex: 1 }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Cidade
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    onChange={(event: any) => {
                      setCityFilter(event.target.value);
                      getNeighborhoods({
                        where: { cityId: { equals: event.target.value } },
                      });
                    }}
                    value={cityFilter}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ContainerRow>

              <DeliverymenList>
                <thead>
                  <ItemTitle>
                    <th>Bairro</th>
                    <th>Cidade</th>
                    <th>Estado</th>
                    <th>Ações</th>
                  </ItemTitle>
                </thead>
                <tbody>
                  {neighborhoods.map((item) => (
                    <CitiesItem
                      key={item.id}
                      item={item}
                      getNeighborhoods={getNeighborhoods}
                      cities={cities}
                    />
                  ))}
                </tbody>
              </DeliverymenList>
              <NeighborhoodModal
                setOpen={setOpen}
                open={open}
                cities={cities}
                handleNeighborhood={createNeighborhood}
              />
              {openState && (
                <StateModal setOpen={setOpenState} open={openState} />
              )}
              {openCity && (
                <CityModal
                  setOpen={setOpenCity}
                  open={openCity}
                  getCities={getCities}
                />
              )}
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default Deliverymen;
