import styled from 'styled-components';

interface ContainerProps {
  checked: boolean;
}

export const Container = styled.div<ContainerProps>`
  min-width: 48px;
  height: 44px;
  border-radius: 35%;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: background 0.3s;
  background: ${(props) => (props.checked ? '#436461' : '#A3C6C2')};
`;
