import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Button, Paper } from '@material-ui/core';

export const ColorButton = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: '#49717A',
    '&:hover': {
      backgroundColor: '#334f55',
    },
  },
}))(Button);

export const ContainerDetail = styled(Paper)`
  width: 180px;
  position: absolute;
  align-self: flex-end;
  margin-top: 15vh;
  padding: 20px;
`;

export const Title = styled.p`
  text-align: center;
  font-family: 'Roboto Slab', serif;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #a0faf2;
  margin-bottom: 5px;
  color: #30d2c3;
`;

export const TextStatus = styled.p`
  font-family: 'Roboto Slab', serif;
  color: #436461;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
`;
