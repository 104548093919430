import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { MdEdit, MdCancel } from 'react-icons/md';
import Dialog from '@material-ui/core/Dialog';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useToast } from '../../../hooks/toast';

import Button from '../../../components/Button';

import { Container, Item, Divider, Options } from './styles';

interface DeliveryFeeItem {
  id: string;
  price: number;
  neighborhood: {
    name: string;
  };
  createdAt: string;
}

interface DeliveryFeeDetailsProps {
  open: boolean;
  setClose(): void;
  item: DeliveryFeeItem;
}

interface FormSubmit {
  accountStatus: string;
}

const UpdateDeliveryFeeRequest = gql`
  mutation updateOneDeliveryman(
    $data: DeliverymanUpdateInput!
    $where: DeliverymanWhereUniqueInput!
  ) {
    updateOneDeliveryman(data: $data, where: $where) {
      id
    }
  }
`;

const DeliveryFeeDetails: React.FC<DeliveryFeeDetailsProps> = ({
  open,
  setClose,
  item,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [updateOneDeliveryman] = useMutation(UpdateDeliveryFeeRequest);
  const [disabled, setDisabled] = useState(true);
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: FormSubmit) => {
      try {
        const { accountStatus } = data;

        await updateOneDeliveryman({
          variables: { data: { accountStatus }, where: { id: item.id } },
        });

        addToast({
          type: 'success',
          title: 'Entregador editado com sucesso.',
        });
        setDisabled(true);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao editar entregador.',
        });
      }
    },
    [updateOneDeliveryman, addToast, item.id],
  );

  return (
    <Dialog
      onClose={() => {
        setClose();
      }}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Container>
        <Item>
          <h1>Valor: </h1>
          <strong>{item.price}</strong>
        </Item>
        <Item>
          <h1>Bairro: </h1>
          <strong>
            {item.neighborhood.name}
          </strong>
        </Item>
        <Divider />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Options>
            {disabled ? (
              <button
                type="button"
                onClick={() => {
                  setDisabled(false);
                }}
              >
                <MdEdit color="#4D85EE" size={19} />
              </button>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button
                  type="button"
                  onClick={() => {
                    setDisabled(true);
                  }}
                >
                  <MdCancel
                    style={{ marginRight: '5px' }}
                    color="red"
                    size={19}
                  />
                </button>
              </div>
            )}
          </Options>
          {!disabled ? <Button type="submit">Editar</Button> : null}
        </Form>

        <Divider />
      </Container>
    </Dialog>
  );
};

export default DeliveryFeeDetails;
