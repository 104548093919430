export default {
  SEARCHING: 'Procurando',
  WAITING_RESPONSE: 'Aguardando resposta',
  WAITING_COLLECT: 'Aguardando coleta',
  IN_PROGRESS: 'Em Progresso',
  FINISHED: 'Finalizado',
  CANCELED: 'Cancelado',
  ONLINE: 'Disponível',
  DELIVERING: 'Entregando',
  RECIEVING_REQUEST: 'Recebendo chamada',
};
