import React, { useRef, useState, useCallback, useEffect } from 'react';

// import { Container } from './styles';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { debounce } from 'lodash';
import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Dialog,
  TextField,
} from '@material-ui/core';
import { ColorButton, ContainerRow } from './styles';

interface MarkerObj {
  lat: number;
  lng: number;
}
const libraries = ['places'];

interface City {
  id: string;
  name: string;
}

interface CitiesModalProps {
  nameNeighborhood?: string;
  cityNeighborhood?: string;
  geolocation?: {
    latitude: string;
    longitude: string;
  };
  setOpen: any;
  open: boolean;
  cities: City[];
  handleNeighborhood: (
    name: string,
    cityId: string,
    latitude: string,
    longitude: string,
  ) => Promise<void>;
}

const NeighborhoodModal: React.FC<CitiesModalProps> = ({
  setOpen,
  open,
  cities,
  handleNeighborhood,
  nameNeighborhood = '',
  cityNeighborhood = '',
  geolocation = {
    latitude: undefined,
    longitude: undefined,
  },
}) => {
  const mapRef = useRef<any>(null);
  const [city, setCity] = useState<string>(cityNeighborhood);
  const [name, setName] = useState<string>(nameNeighborhood);

  const [initialPosition, setInitialPosition] = useState<[number, number]>([
    0,
    0,
  ]);
  const [marker, setMarker] = useState<MarkerObj | null>(
    geolocation.latitude !== undefined
      ? {
          lat: Number(geolocation.latitude),
          lng: Number(geolocation.longitude),
        }
      : null,
  );
  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries,
  });

  const handleCenterChanged = useCallback(
    debounce(() => {
      if (mapRef.current) {
        const newPos = mapRef.current.getCenter().toJSON();
        setInitialPosition([newPos.lat, newPos.lng]);
      }
    }, 500),
    [],
  );

  const onMapClick = useCallback((e) => {
    setMarker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }, []);

  useEffect(() => {
    if (geolocation.latitude && geolocation.longitude) {
      setInitialPosition([
        Number(geolocation.latitude),
        Number(geolocation.longitude),
      ]);
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;

        setInitialPosition([latitude, longitude]);
      });
    }
  }, [geolocation]);

  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{ style: { maxWidth: '100%' } }}
    >
      <ContainerRow>
        <TextField
          style={{ flex: 1, marginRight: 20 }}
          variant="outlined"
          required
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Nome do bairro"
        />
        <FormControl variant="outlined" style={{ flex: 1 }}>
          <InputLabel id="demo-simple-select-filled-label">Cidade</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            onChange={(event: any) => {
              setCity(event.target.value);
            }}
            value={city}
          >
            {cities.map((item: City) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ContainerRow>
      <GoogleMap
        onLoad={(map) => {
          mapRef.current = map;
        }}
        onCenterChanged={handleCenterChanged}
        mapContainerStyle={{ width: '90vw', height: '100vh' }}
        zoom={18}
        center={{ lat: initialPosition[0], lng: initialPosition[1] }}
        onClick={onMapClick}
      >
        {marker && (
          <Marker
            key={`${marker.lat}-${marker.lng}`}
            position={{ lat: marker.lat, lng: marker.lng }}
          />
        )}
      </GoogleMap>
      {marker && (
        <ColorButton
          style={{
            width: '20%',
            position: 'absolute',
            right: '40%',
            top: '90%',
          }}
          onClick={() => {
            setName('');
            setCity('');
            setMarker(null);
            handleNeighborhood(
              name,
              city,
              marker?.lat.toString(),
              marker?.lng.toString(),
            );
          }}
        >
          Confirmar
        </ColorButton>
      )}
    </Dialog>
  );
};

export default NeighborhoodModal;
