import React from 'react';
import { FiCheck } from 'react-icons/fi';

import { Container } from './styles';

interface CheckboxProps {
  check: boolean;
  onClick: Function;
}

const Button: React.FC<CheckboxProps> = ({ check, onClick }) => (
  <Container checked={check} onClick={() => onClick()}>
    {check ? <FiCheck size={30} /> : null}
  </Container>
);

export default Button;
