import styled from 'styled-components';
import { darken } from 'polished';

interface OptionsListProps {
  visible: boolean;
}

// Last item is span to open options list
// TO RESIZE SPACES ON TABLE ITEMS ->
// margin-bottom on: td {div{ }} and LastItem

export const Container = styled.tr`
  td {
    div {
      height: 57px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1rem;
      font-weight: 100;
      background: #fff;
      margin-bottom: 16px;
      color: #666666;
      display: flex;
      align-items: center;
      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const FirstItem = styled.div`
  padding-left: 13px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const LastItem = styled.span`
  display: flex;
  height: 57px;
  justify-content: flex-end;
  margin-bottom: 16px;
  background: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const Badge = styled.button`
  padding: 14px 28px;
  background: none;
  border: 0;
  &:hover {
    background: ${darken(0.02, '#fff')};
  }
`;

export const OptionsContainer = styled.span`
  position: relative;
`;

export const OptionsList = styled.span<OptionsListProps>`
  position: absolute;
  z-index: 10;
  width: 150px;
  left: calc(50% - 73px);
  top: 100%;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 5px;
  display: ${(props) => (props.visible ? 'block' : 'none')};

  &::before {
    content: '▲';
    position: absolute;
    left: 71px;
    top: -13px;
    width: 0;
    height: 0;
    color: #fff;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0.25);
  }

  span {
    display: flex;
    align-content: center;
    margin-left: 10px;
    margin-right: 10px;

    svg {
      margin-bottom: 6px;
      margin-top: 6px;
    }

    p {
      font-size: 1rem;
      margin-left: 14px;
      font-weight: lighter;
      color: #999999;
      margin-bottom: 6px;
      margin-top: 6px;
    }

    button {
      background: none;
      border: 0;
      display: flex;
      flex: 1;
      align-items: center;

      &:hover {
        background: ${darken(0.02, '#fff')};
      }
    }
  }
`;
