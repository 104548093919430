import React, { useState, useCallback, useRef, useEffect } from 'react';
import { MdMoreHoriz, MdRemoveRedEye } from 'react-icons/md';

import { useHistory } from 'react-router-dom';
import DeliverymenDetails from '../DeliverymenDetails';
import { EvaluationModal } from '../../../components';
import { ACCOUNT_STATUS, VENICHE_TYPE } from '../../../utils/constants';

import {
  Container,
  FirstItem,
  LastItem,
  Badge,
  OptionsList,
  OptionsContainer,
} from './styles';

interface DeliverymenItemProps {
  item: {
    id: string;
    name: string;
    email: string;
    avatarUrl: string;
    cpf: string;
    city: {
      name: string;
    };
    state: {
      name: string;
    };
    documentUrl: string;
    phone: string;
    proofOfAddressUrl: string;
    accountStatus: string;
    veniche: {
      type: string;
      plate: string;
      brand: string;
      model: string;
      color: string;
      owner: {
        name: string;
      };
      crlvUrl: string;
    };
    createdAt: string;
  };

  update(id: string, newStatus: string): void;
}

const DeliverymenItem: React.FC<DeliverymenItemProps> = ({ item, update }) => {
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEvaluation, setOpenEvaluetion] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);
  const history = useHistory();

  const handleClickOutside = useCallback((event: any) => {
    if (ref.current && !ref.current.contains(event.target)) setVisible(false);
  }, []);

  const handleToggleVisible = useCallback(() => {
    setVisible((state) => !state);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Container>
      <td>
        <FirstItem>
          <p>{item.name}</p>
        </FirstItem>
      </td>
      <td>
        <div>
          <p>{item.email}</p>
        </div>
      </td>
      <td>
        <div>
          <p>{ACCOUNT_STATUS[item.accountStatus]}</p>
        </div>
      </td>
      <td>
        <div>
          <p>{VENICHE_TYPE[item.veniche.type]}</p>
        </div>
      </td>
      <td>
        <LastItem>
          <OptionsContainer ref={ref}>
            <Badge onClick={() => handleToggleVisible()}>
              <MdMoreHoriz color="#C6C6C6" size={25} />
            </Badge>
            <OptionsList visible={visible}>
              <span>
                <button
                  type="button"
                  onClick={() => {
                    setOpen(true);
                    setVisible(false);
                  }}
                >
                  <MdRemoveRedEye color="#4D85EE" size={16} />
                  <p>Status</p>
                </button>
              </span>
              <span>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEvaluetion(true);
                    setVisible(false);
                  }}
                >
                  <MdRemoveRedEye color="#4D85EE" size={16} />
                  <p>Avaliações</p>
                </button>
              </span>
              <span>
                <button
                  type="button"
                  onClick={() => {
                    history.push(`/deliverymanEdit/${item.id}`);
                  }}
                >
                  <MdRemoveRedEye color="#4D85EE" size={16} />
                  <p>Editar</p>
                </button>
              </span>
            </OptionsList>
          </OptionsContainer>
        </LastItem>
      </td>
      <DeliverymenDetails
        open={open}
        setClose={() => setOpen(false)}
        item={item}
        update={update}
      />

      <EvaluationModal
        open={openEvaluation}
        setOpen={setOpenEvaluetion}
        item={item}
      />
    </Container>
  );
};

export default DeliverymenItem;
