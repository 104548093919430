import React, { useState, useEffect, useCallback, useRef } from 'react';
import { InputLabel, FormControl, MenuItem, Select } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { last, first, get } from 'lodash';
import { Add } from '@material-ui/icons';
import { useToast } from '../../hooks/toast';
import DeliveryFeeItem from './DeliveryFeeItem';
import { DeliveryFeeModal } from '../../components';
import {
  Container,
  DeliveryFeeList,
  ItemTitle,
  ContainerRow,
  ColorButton,
} from './styles';
import { useAuth } from '../../hooks/auth';
import Loading from '../../components/Loading';
import client from '../../services/graphqlClient';

const GetNeighborhoods = gql`
  query(
    $where: NeighborhoodWhereInput
    $after: NeighborhoodWhereUniqueInput
    $before: NeighborhoodWhereUniqueInput
    $first: Int
    $last: Int
  ) {
    neighborhoods(
      where: $where
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: { name: asc }
    ) {
      id
      name
      city {
        id
        name
        state {
          id
          name
        }
      }
      geolocation {
        latitude
        longitude
      }
    }
  }
`;

const GetDeliveryFee = gql`
  query(
    $where: DeliveryFeeWhereInput
    $after: DeliveryFeeWhereUniqueInput
    $before: DeliveryFeeWhereUniqueInput
    $first: Int
    $last: Int
  ) {
    deliveryFees(
      where: $where
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: { createdAt: desc }
    ) {
      id
      price
      createdAt
      neighborhood {
        name
      }
    }
  }
`;

const AddDeliveryFee = gql`
  mutation($data: DeliveryFeeCreateInput!) {
    createOneDeliveryFee(data: $data) {
      id
      price
    }
  }
`;

const GetCities = gql`
  query(
    $where: CityWhereInput
    $after: CityWhereUniqueInput
    $before: CityWhereUniqueInput
    $first: Int
    $last: Int
  ) {
    cities(
      where: $where
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: { name: asc }
    ) {
      id
      name
    }
  }
`;

interface UserInfo {
  role: string;
}

interface City {
  id: string;
  name: string;
}

interface Neighborhood {
  id: string;
  name: string;
  city: {
    id: string;
    name: string;
    state: {
      id: string;
      name: string;
    };
  };
  geolocation: {
    latitude: string;
    longitude: string;
  };
}

interface DeliveryFee {
  id: string;
  price: number;
  createdAt: string;
  neighborhood: {
    name: string;
  };
}

const amountOrders = 20;

const DeliveryFees: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [cityFilter, setCityFilter] = useState<string>('1');
  const [cities, setCities] = useState<City[]>([]);
  const [neighborhoods, setNeighborhoods] = useState<Neighborhood[]>([]);
  const [open, setOpen] = useState(false);
  const [deliveryFees, setDeliveryFees] = useState<DeliveryFee[]>([]);
  const { user } = useAuth();
  const [page, setPage] = useState(1);
  const [userInfo] = useState(user as UserInfo);
  const [firstIndex, setFirstIndex] = useState<string | null>(null);
  const [lastIndex, setLastIndex] = useState<string | null>(null);

  const { addToast } = useToast();

  const getCities = useCallback(async (dataParams = {}) => {
    const { data } = await client.query({
      query: GetCities,
      fetchPolicy: 'no-cache',
      variables: {
        ...dataParams,
      },
    });

    if (data.cities.length) {
      setCities(data.cities);
    }
  }, []);

  const getNeighborhoods = useCallback(async (dataParams = {}) => {
    const { data } = await client.query({
      query: GetNeighborhoods,
      fetchPolicy: 'no-cache',
      variables: {
        ...dataParams,
      },
    });

    if (data.neighborhoods.length) {
      setNeighborhoods(data.neighborhoods);
    }
  }, []);

  const getDeliveryFee = useCallback(async (dataParams = {}, pageIndex = 0) => {
    setLoading(true);

    const { data } = await client.query({
      query: GetDeliveryFee,
      fetchPolicy: 'no-cache',
      variables: {
        ...dataParams,
      },
    });

    if (data.deliveryFees.length) {
      setDeliveryFees(data.deliveryFees);

      const firstValue = get(first(data.deliveryFees), 'id', null);
      const lastValue = get(last(data.deliveryFees), 'id', null);

      setFirstIndex(firstValue);
      setLastIndex(lastValue);
      setPage((prevState) => prevState + pageIndex);
    }

    if (data.deliveryFees.length === 0) {
      setDeliveryFees(data.deliveryFees);
    }

    setLoading(false);
  }, []);

  const createDeliveryFee = useCallback(
    async (price, neighborhoodId) => {
      try {
        await client.mutate({
          mutation: AddDeliveryFee,
          variables: {
            data: {
              price,
              neighborhood: {
                connect: {
                  id: neighborhoodId,
                },
              },
            },
          },
        });
        setOpen(false);
        await getDeliveryFee();
        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ops, a taxa de entrega não pode ser cadastrada',
        });
      }
    },
    [getDeliveryFee, addToast],
  );

  useEffect(() => {
    setLoading(true);
    getDeliveryFee({ first: amountOrders });
    getNeighborhoods({ where: { cityId: { equals: 1 } } });
    getCities();
  }, [getDeliveryFee, getNeighborhoods, getCities]);

  return (
    <>
      {userInfo.role === 'DEFAULT' ? (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      ) : (
        <Container>
          {loading ? (
            <Loading />
          ) : (
            <>
              <ContainerRow style={{ width: '25%' }}>
                <ColorButton
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  style={{ height: 50, flex: 1 }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Criar Taxa de Entrega
                </ColorButton>
              </ContainerRow>

              <ContainerRow style={{ marginTop: 20, width: '25%' }}>
                <FormControl variant="outlined" style={{ flex: 1 }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Cidade
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    onChange={(event: any) => {
                      setCityFilter(event.target.value);
                      getNeighborhoods({
                        where: { cityId: { equals: event.target.value } },
                      });
                    }}
                    value={cityFilter}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ContainerRow>

              <DeliveryFeeList>
                <thead>
                  <ItemTitle>
                    <th>Bairro</th>
                    <th>Preço</th>
                    <th>Ações</th>
                  </ItemTitle>
                </thead>
                <tbody>
                  {deliveryFees.map((item) => (
                    <DeliveryFeeItem key={item.id} item={item} />
                  ))}
                </tbody>
              </DeliveryFeeList>
              <DeliveryFeeModal
                setOpen={setOpen}
                open={open}
                neighborhoods={neighborhoods}
                handleDeliveryFee={createDeliveryFee}
              />
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default DeliveryFees;
