import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  max-width: 90%;
  padding: 15px 0;
  margin: 0 auto;
  flex-direction: column;
`;

export const DeliveryFeeList = styled.table`
  margin-top: 22px;
  border-collapse: collapse;
  width: 100%;
`;

export const ItemTitle = styled.tr`
  th:last-child {
    padding-right: 13px;
    text-align: right;
  }
  th:first-child {
    padding-left: 13px;
  }
  th {
    font-size: 1rem;
    text-align: left;
    padding-bottom: 14px;
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ColorButton = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: '#49717A',
    '&:hover': {
      backgroundColor: '#334f55',
    },
  },
}))(Button);
