import React, { useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { format, parseISO } from 'date-fns';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import Button from '../../../../components/Button';
import { applyPriceMask } from '../../../../utils/priceMask.js';
import { useToast } from '../../../../hooks/toast';

import { Container } from './styles';
import { useAuth } from '../../../../hooks/auth';

interface OrderItem {
  id: string;
  status: string;
  user: { name: string };
  deliveryman: { name: string };
  deliveries: {
    id: string;
    status: string;
    neighborhood: {
      name: string;
    };
    price: number;
  }[];
  createdAt: string;
  updatedAt: string;
}

interface OrderDetailProps {
  open: boolean;
  setClose(): void;
  order: OrderItem;
}

const CancelRequestDelivery = gql`
  mutation cancelDeliveryOrder($data: CancelDeliveryOrderInput!) {
    cancelDeliveryOrder(data: $data) {
      deliveryOrder {
        id
      }
    }
  }
`;

const CollectDelivery = gql`
  mutation collectDeliveryOrderByAdmin($data: CollectDeliveryOrderInput!) {
    collectDeliveryOrderByAdmin(data: $data) {
      deliveryOrder {
        id
      }
    }
  }
`;

const FinishDelivery = gql`
  mutation finishDeliveryOrderByAdmin($data: FinishDeliveryOrderInput!) {
    finishDeliveryOrderByAdmin(data: $data) {
      deliveryOrder {
        id
      }
    }
  }
`;

const OrderDetail: React.FC<OrderDetailProps> = ({ open, setClose, order }) => {
  const [cancelDeliveryOrder] = useMutation(CancelRequestDelivery);
  const [collectDeliveryOrderByAdmin] = useMutation(CollectDelivery);
  const [finishDeliveryOrderByAdmin] = useMutation(FinishDelivery);
  const { addToast } = useToast();
  const { user } = useAuth();

  const handleCancelOrder = useCallback(async () => {
    const confirm = window.confirm(
      'Você tem certeza que deseja cancelar a entrega?',
    );

    if (!confirm) {
      return;
    }

    try {
      await cancelDeliveryOrder({
        variables: { data: { deliveryOrderId: order.id } },
      });

      addToast({
        type: 'success',
        title: 'Entrega cancelada com sucesso.',
        description: 'Verifique os status no quadro de entregas.',
      });
      setClose();
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Ops, um momento.',
        description: 'Não é possível cancelar a entrega nesse passo.',
      });
    } finally {
      setClose();
    }
  }, [addToast, cancelDeliveryOrder, order.id, setClose]);

  const handleCollectOrder = useCallback(async () => {
    const confirm = window.confirm(
      'Você tem certeza que deseja alterar o status da entrega?',
    );

    if (!confirm) {
      return;
    }

    try {
      await collectDeliveryOrderByAdmin({
        variables: { data: { deliveryOrderId: order.id } },
      });

      addToast({
        type: 'success',
        title: 'Status da entrega alterado com sucesso.',
        description: 'Verifique os status no quadro de entregas.',
      });
      setClose();
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Ops, um momento.',
        description: 'Não foi possível alterar o status da entrega.',
      });
    } finally {
      setClose();
    }
  }, [addToast, collectDeliveryOrderByAdmin, order.id, setClose]);

  const handleFinishOrder = useCallback(async () => {
    const confirm = window.confirm(
      'Você tem certeza que deseja alterar o status da entrega?',
    );

    if (!confirm) {
      return;
    }

    try {
      await finishDeliveryOrderByAdmin({
        variables: { data: { deliveryOrderId: order.id } },
      });

      addToast({
        type: 'success',
        title: 'Status da entrega alterado com sucesso.',
        description: 'Verifique os status no quadro de entregas.',
      });
      setClose();
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Ops, um momento.',
        description: 'Não foi possível alterar o status da entrega.',
      });
    } finally {
      setClose();
    }
  }, [addToast, finishDeliveryOrderByAdmin, order.id, setClose]);

  return (
    <Dialog
      open={open}
      onClose={() => setClose()}
      aria-labelledby="simple-dialog-title"
    >
      <Container>
        <div>
          <h1>ID: </h1>
          <strong>{order.id}</strong>
        </div>
        <div>
          <h1>Cliente:</h1>
          <strong>{order.user.name}</strong>
        </div>
        {order.status !== 'CANCELED' ? (
          <div>
            <h1>Entregador: </h1>
            {order.deliveryman ? (
              <strong>{order.deliveryman.name}</strong>
            ) : (
              <strong>Procurando...</strong>
            )}
          </div>
        ) : null}
        <div>
          <h1>Data & Hora:</h1>
          <strong>
            {format(parseISO(order.createdAt), 'dd/MM/yyyy hh:mm')}
          </strong>
        </div>
        {order.deliveries &&
          order.deliveries.map((delivery, idx) => (
            <span key={delivery.id}>
              <h1>{`Entrega ${idx + 1}`}</h1>
              <div>
                <h1>ID:</h1>
                <strong>{delivery.id}</strong>
              </div>
              {/* <div>
                <h1>Status:</h1>
                <strong>{delivery.status}</strong>
              </div> */}
              <div>
                <h1>Bairro:</h1>
                <strong>{delivery.neighborhood.name}</strong>
              </div>
              <div>
                <h1>Valor:</h1>
                <strong>{applyPriceMask(delivery.price)}</strong>
              </div>
            </span>
          ))}

        {order.status === 'WAITING_COLLECT' && user.role !== 'DEFAULT' && (
          <Button
            style={{ background: '#4A717A' }}
            onClick={() => {
              handleCollectOrder();
            }}
          >
            Coletar
          </Button>
        )}

        {order.status === 'IN_PROGRESS' && user.role !== 'DEFAULT' && (
          <Button
            style={{ background: '#4A717A' }}
            onClick={() => {
              handleFinishOrder();
            }}
          >
            Finalizar
          </Button>
        )}

        {(order.status === 'SEARCHING' ||
          order.status === 'WAITING_RESPONSE') && (
          <Button
            style={{ background: 'red' }}
            onClick={() => {
              handleCancelOrder();
            }}
          >
            Cancelar
          </Button>
        )}

        {(order.status === 'WAITING_COLLECT' ||
          order.status === 'IN_PROGRESS') &&
          user.role !== 'DEFAULT' && (
            <Button
              style={{ background: 'red' }}
              onClick={() => {
                handleCancelOrder();
              }}
            >
              Cancelar
            </Button>
          )}
      </Container>
    </Dialog>
  );
};

export default OrderDetail;
