import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { MdEdit, MdCancel } from 'react-icons/md';
import Dialog from '@material-ui/core/Dialog';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { ACCOUNT_STATUS } from '../../../utils/constants';

import { useToast } from '../../../hooks/toast';

import Button from '../../../components/Button';
import Select from '../../../components/Select';

import { Container, Item, Divider, Options, Documents } from './styles';

interface DeliverymenItem {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
  cpf: string;
  city: {
    name: string;
  };
  state: {
    name: string;
  };
  documentUrl: string;
  phone: string;
  proofOfAddressUrl: string;
  accountStatus: string;
  veniche: {
    type: string;
    plate: string;
    brand: string;
    model: string;
    color: string;
    owner: {
      name: string;
    };
    crlvUrl: string;
  };
  createdAt: string;
}

interface DeliverymenDetailsProps {
  open: boolean;
  setClose(): void;
  item: DeliverymenItem;
  update(id: string, newStatus: string): void;
}

interface FormSubmit {
  accountStatus: string;
}

const UpdateDeliverymenRequest = gql`
  mutation updateOneDeliveryman(
    $data: DeliverymanUpdateInput!
    $where: DeliverymanWhereUniqueInput!
  ) {
    updateOneDeliveryman(data: $data, where: $where) {
      id
    }
  }
`;

const DeliverymenDetails: React.FC<DeliverymenDetailsProps> = ({
  open,
  setClose,
  item,
  update,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [updateOneDeliveryman] = useMutation(UpdateDeliverymenRequest);
  const [disabled, setDisabled] = useState(true);
  const { addToast } = useToast();

  const statusOptions = [
    { value: 'PENDING', label: 'Pendente' },
    { value: 'APPROVED', label: 'Aprovado' },
    { value: 'RECUSED', label: 'Recusado' },
    { value: 'SUSPENDED', label: 'Suspenso' },
  ];

  const handleSubmit = useCallback(
    async (data: FormSubmit) => {
      try {
        const { accountStatus } = data;

        await updateOneDeliveryman({
          variables: { data: { accountStatus }, where: { id: item.id } },
        });

        addToast({
          type: 'success',
          title: 'Entregador editado com sucesso.',
        });
        setDisabled(true);
        update(item.id, accountStatus);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao editar entregador.',
        });
      }
    },
    [updateOneDeliveryman, addToast, item.id, update],
  );

  return (
    <Dialog
      onClose={() => {
        setClose();
      }}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Container>
        <Item>
          <h1>Cliente: </h1>
          <strong>{item.name}</strong>
        </Item>
        <Item>
          <h1>Email:</h1>
          <strong>{item.email}</strong>
        </Item>
        <Item>
          <h1>CPF: </h1>
          <strong>{item.cpf}</strong>
        </Item>
        <Item>
          <h1>Telefone: </h1>
          <strong>{item.phone}</strong>
        </Item>
        <Item>
          <h1>Cidade: </h1>
          <strong>
            {item.city.name} - {item.state.name}
          </strong>
        </Item>
        <Item>
          <h1>Veículo:</h1>
          <strong>{item.veniche.brand}</strong>
        </Item>
        <Item>
          <h1>Placa:</h1>
          <strong>{item.veniche.plate}</strong>
        </Item>
        <Item>
          <h1>Cor do veículo:</h1>
          <strong>{item.veniche.color}</strong>
        </Item>

        <Divider />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Options>
            {disabled ? (
              <button
                type="button"
                onClick={() => {
                  setDisabled(false);
                }}
              >
                <MdEdit color="#4D85EE" size={19} />
              </button>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button
                  type="button"
                  onClick={() => {
                    setDisabled(true);
                  }}
                >
                  <MdCancel
                    style={{ marginRight: '5px' }}
                    color="red"
                    size={19}
                  />
                </button>
              </div>
            )}
          </Options>
          <Select
            name="accountStatus"
            isDisabled={disabled}
            label="Status"
            options={statusOptions}
            defaultValue={{
              value: item.accountStatus,
              label: ACCOUNT_STATUS[item.accountStatus],
            }}
          />
          {!disabled ? <Button type="submit">Editar</Button> : null}
        </Form>

        <Divider />
        <Documents>
          {item.avatarUrl && (
            <div>
              <img alt="selfie" src={item.avatarUrl} />
              <strong>Selfie</strong>
            </div>
          )}
          {item.documentUrl && (
            <div>
              <img alt="cnh" src={item.documentUrl} />
              <strong>CNH</strong>
            </div>
          )}
          {item.proofOfAddressUrl && (
            <div>
              <img alt="comprovante" src={item.proofOfAddressUrl} />
              <strong>Comprovante de Residência</strong>
            </div>
          )}
          {item.veniche.crlvUrl && (
            <div>
              <img alt="comprovante" src={item.veniche.crlvUrl} />
              <strong>CRLV</strong>
            </div>
          )}
        </Documents>
      </Container>
    </Dialog>
  );
};

export default DeliverymenDetails;
