import gql from 'graphql-tag';

export const ADD_NEIGHBORHOODS = gql`
  mutation($data: NeighborhoodCreateInput!) {
    createOneNeighborhood(data: $data) {
      id
      name
    }
  }
`;

export const DELETE_NEIGHBORHOOD = gql`
  mutation($where: NeighborhoodWhereUniqueInput!) {
    deleteOneNeighborhood(where: $where) {
      id
      name
    }
  }
`;

export const UPDATE_NEIGHBORHOOD = gql`
  mutation(
    $data: NeighborhoodUpdateInput!
    $where: NeighborhoodWhereUniqueInput!
  ) {
    updateOneNeighborhood(data: $data, where: $where) {
      id
      name
    }
  }
`;

export const UPDATE_STATE = gql`
  mutation($data: StateUpdateInput!, $where: StateWhereUniqueInput!) {
    updateOneState(data: $data, where: $where) {
      id
      name
    }
  }
`;

export const UPDATE_ONE_DELIVERYMAN = gql`
  mutation(
    $data: DeliverymanUpdateInput!
    $where: DeliverymanWhereUniqueInput!
  ) {
    updateOneDeliveryman(data: $data, where: $where) {
      id
      status
      accountStatus
    }
  }
`;

export const CHANGE_DELIVERYMAN_STATUS = gql`
  mutation($data: ChangeDeliverymanStatusForOfflineInput!) {
    changeDeliverymanStatusForOffline(data: $data) {
      deliveryman {
        id
        status
        accountStatus
      }
    }
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation resetUserPassword($data: ResetUserPasswordInput!) {
    resetUserPassword(data: $data) {
      message
    }
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation changeUserPassword($data: ChangeDeliverymanPasswordInput!) {
    changeUserPassword(data: $data) {
      message
    }
  }
`;
