import { startOfWeek, format, parseISO } from 'date-fns';
import React, { useState, useEffect, useCallback } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import { NavigateNext, NavigateBefore, Search } from '@material-ui/icons';
import { last, first, get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import ReactExport from 'react-export-excel';

import {
  IconButton,
  Typography,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Button,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import client from '../../services/graphqlClient';
import { applyPriceMask } from '../../utils/priceMask.js';

import Loading from '../../components/Loading';

import ExtractItem from './ExtractItem';
import { useAuth } from '../../hooks/auth';

import {
  Container,
  ExtractList,
  ItemTitle,
  // PaginationContainer,
  ContainerRow,
} from './styles';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const GetExtract = gql`
  query(
    $where: DeliveryOrderWhereInput
    $after: DeliveryOrderWhereUniqueInput
    $before: DeliveryOrderWhereUniqueInput
    $first: Int
    $last: Int
  ) {
    deliveryOrders(
      where: $where
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: { createdAt: desc }
    ) {
      id
      user {
        name
      }
      deliveryman {
        name
      }
      deliveries {
        id
        status
        neighborhood {
          name
        }
        price
        updatedAt
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

const GetCities = gql`
  query($where: UserWhereInput) {
    users(where: $where) {
      id
      name
    }
  }
`;

interface Extract {
  id: string;
  user: { name: string };
  deliveryman: { name: string };
  deliveries: {
    id: string;
    status: string;
    neighborhood: {
      name: string;
    };
    price: number;
  }[];
  createdAt: string;
  updatedAt: string;
}

interface Campany {
  id: number;
  name: string;
}

interface UserInfo {
  name: string;
  role: string;
}

const amountOrders = 20;
const STATUS = [
  {
    label: 'Finalizadas',
    value: 'FINISHED',
  },
  {
    label: 'Canceladas',
    value: 'CANCELED',
  },
];

const Extract: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [extract, setExtract] = useState<Extract[]>([]);
  const [extractCSV, setExtractCSV] = useState<Extract[]>([]);

  const [page, setPage] = useState(1);
  const [firstIndex, setFirstIndex] = useState<string | null>(null);
  const [lastIndex, setLastIndex] = useState<string | null>(null);
  const [companies, setCompanies] = useState<Campany[]>([]);
  const [companyFilter, setCompanyFilter] = useState<string>('');
  const [statusOrder, setStatusOrder] = useState('FINISHED');
  const { user, loadUser } = useAuth();
  const [userInfo] = useState(user as UserInfo);

  const [initialDate, setInitialDate] = useState<Date | null>(
    startOfWeek(new Date()),
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const getDeliveriesOrders = useCallback(
    async (dataParams = {}, pageIndex = 0) => {
      setLoading(true);
      setExtractCSV([]);
      const userIdValue = companyFilter
        ? {
            userId: {
              equals: companyFilter,
            },
          }
        : {};

      const { data } = await client.query({
        query: GetExtract,
        variables: {
          where: {
            createdAt: {
              gte: new Date(
                parseISO(
                  format(initialDate || new Date(), "yyyy-MM-dd'T'00:00:00"),
                ),
              ),
              lte: new Date(
                parseISO(
                  format(endDate || new Date(), "yyyy-MM-dd'T'23:59:59"),
                ),
              ),
            },
            status: statusOrder,
            ...userIdValue,
          },
          ...dataParams,
        },
      });

      if (data.deliveryOrders.length) {
        setExtract(data.deliveryOrders);

        const firstValue = get(first(data.deliveryOrders), 'id', null);
        const lastValue = get(last(data.deliveryOrders), 'id', null);

        setFirstIndex(firstValue);
        setLastIndex(lastValue);
        setPage((prevState) => prevState + pageIndex);
      }

      setLoading(false);
    },
    [companyFilter, endDate, initialDate, statusOrder],
  );

  const [getCompanies] = useLazyQuery(GetCities, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setCompanies(response.users);
    },
  });

  useEffect(() => {
    loadUser();
    getDeliveriesOrders({ first: amountOrders });
    getCompanies();
  }, [getDeliveriesOrders, getCompanies]); // eslint-disable-line

  const backPage = useCallback(() => {
    if (page > 1) {
      getDeliveriesOrders(
        { before: { id: firstIndex }, last: amountOrders },
        -1,
      );
    }
  }, [page, getDeliveriesOrders, firstIndex]);

  const nextPage = useCallback(() => {
    if (extract.length === amountOrders && lastIndex) {
      getDeliveriesOrders({ after: { id: lastIndex }, first: amountOrders }, 1);
    }
  }, [extract, getDeliveriesOrders, lastIndex]);

  const handleTotalPrice = (deliveries: any): number => {
    let total = 0;
    deliveries.forEach((delivery: any) => {
      total += delivery.price;
    });

    return total;
  };

  const handleBuildCSV = useCallback(async () => {
    const userIdValue = companyFilter
      ? {
          userId: {
            equals: companyFilter,
          },
        }
      : {};

    const { data } = await client.query({
      query: GetExtract,
      variables: {
        where: {
          createdAt: {
            gte: new Date(
              parseISO(
                format(initialDate || new Date(), "yyyy-MM-dd'T'00:00:00"),
              ),
            ),
            lte: new Date(
              parseISO(format(endDate || new Date(), "yyyy-MM-dd'T'23:59:59")),
            ),
          },
          status: statusOrder,
          ...userIdValue,
        },
      },
    });

    const response = data.deliveryOrders.map((callDelivery: any) => ({
      pedido: `#${callDelivery.id}`,
      'hora/data': format(parseISO(callDelivery.createdAt), 'dd/MM/yyyy hh:mm'),
      estabelecimento: get(callDelivery, 'user.name', ''),
      entregador: get(callDelivery, 'deliveryman.name', ''),
      total: applyPriceMask(handleTotalPrice(callDelivery.deliveries)),
      '1entrega': get(callDelivery, 'deliveries[0].id', '')
        ? `#${callDelivery.deliveries[0].id}`
        : '',
      '1valor': get(callDelivery, 'deliveries[0].price', '')
        ? applyPriceMask(callDelivery.deliveries[0].price)
        : '',
      '1bairro': get(callDelivery, 'deliveries[0].neighborhood.name', ''),
      '2entrega': get(callDelivery, 'deliveries[1].id', '')
        ? `#${callDelivery.deliveries[1].id}`
        : '',
      '2valor': get(callDelivery, 'deliveries[1].price', '')
        ? applyPriceMask(callDelivery.deliveries[1].price)
        : '',
      '2bairro': get(callDelivery, 'deliveries[1].neighborhood.name', ''),
      '3entrega': get(callDelivery, 'deliveries[2].id', '')
        ? `#${callDelivery.deliveries[2].id}`
        : '',
      '3valor': get(callDelivery, 'deliveries[2].price', '')
        ? applyPriceMask(callDelivery.deliveries[2].price)
        : '',
      '3bairro': get(callDelivery, 'deliveries[2].neighborhood.name', ''),
      '4entrega': get(callDelivery, 'deliveries[3].id', '')
        ? `#${callDelivery.deliveries[3].id}`
        : '',
      '4valor': get(callDelivery, 'deliveries[3].price', '')
        ? applyPriceMask(callDelivery.deliveries[3].price)
        : '',
      '4bairro': get(callDelivery, 'deliveries[3].neighborhood.name', ''),
    }));

    setExtractCSV(response);
  }, [companyFilter, endDate, initialDate, statusOrder]);

  const ColorButton = withStyles(() => ({
    root: {
      color: 'white',
      backgroundColor: '#49717A',
      '&:hover': {
        backgroundColor: '#334f55',
      },
    },
  }))(Button);

  const ColorTypography = withStyles({
    root: {
      color: '#334f55',
    },
  })(Typography);

  return (
    <Container>
      {loading && <Loading />}
      {!loading && (
        <>
          <div style={{ alignSelf: 'flex-end', display: 'flex' }}>
            <div style={{ width: '100%' }}>
              <ContainerRow style={{ width: '100%' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data inicial"
                    value={initialDate}
                    onChange={setInitialDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    style={{ marginRight: 10 }}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data final"
                    value={endDate}
                    onChange={setEndDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </ContainerRow>
              <ContainerRow style={{ width: '100%' }}>
                {userInfo.role !== 'DEFAULT' && (
                  <>
                    <FormControl
                      variant="outlined"
                      style={{ flex: 1, margin: 10 }}
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Empresa
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        onChange={(event: any) =>
                          setCompanyFilter(event.target.value)}
                        value={companyFilter}
                      >
                        {companies.map((company) => (
                          <MenuItem key={company.id} value={company.id}>
                            {company.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      variant="outlined"
                      style={{ flex: 1, margin: 10 }}
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        onChange={(event: any) =>
                          setStatusOrder(event.target.value)}
                        value={statusOrder}
                      >
                        {STATUS.map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}

                <ColorButton
                  variant="contained"
                  color="primary"
                  startIcon={<Search />}
                  style={{ height: 50, margin: 10 }}
                  onClick={() => {
                    getDeliveriesOrders({
                      first: amountOrders,
                    });
                    setExtractCSV([]);
                    setPage(1);
                  }}
                >
                  Filtrar
                </ColorButton>
              </ContainerRow>
            </div>
          </div>

          <ExtractList>
            <thead>
              <ItemTitle>
                <th>Pedido</th>
                <th>Hora & Data</th>
                <th>Cliente</th>
                <th>Entregador</th>
                <th>Valor</th>
                <th>Ações</th>
              </ItemTitle>
            </thead>
            <tbody>
              {extract.map((item) => (
                <ExtractItem key={item.id} item={item} />
              ))}
            </tbody>
          </ExtractList>
          <ContainerRow
            style={{ justifyContent: 'flex-end', alignItems: 'center' }}
          >
            <IconButton
              aria-label="delete"
              onClick={backPage}
              disabled={loading}
            >
              <NavigateBefore />
            </IconButton>
            <ColorTypography variant="h6" color="secondary">
              {page}
            </ColorTypography>
            <IconButton
              aria-label="delete"
              onClick={nextPage}
              disabled={loading}
            >
              <NavigateNext />
            </IconButton>
          </ContainerRow>

          {extractCSV.length ? (
            <div
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: 'relative',
              }}
            >
              <ExcelFile
                filename="ta-na-mao"
                element={(
                  <Button
                    variant="contained"
                    color="default"
                    style={{
                      width: '100%',
                    }}
                  >
                    Baixar Relatório
                  </Button>
                )}
              >
                <ExcelSheet data={extractCSV} name="ta-na-mao">
                  <ExcelColumn label="Pedido" value="pedido" />
                  <ExcelColumn label="Hora/Data" value="hora/data" />
                  <ExcelColumn
                    label="Estabelecimento"
                    value="estabelecimento"
                  />
                  <ExcelColumn label="Entregador" value="entregador" />
                  <ExcelColumn label="Total" value="total" />
                  <ExcelColumn label="1 Entrega" value="1entrega" />
                  <ExcelColumn label="1 Valor" value="1valor" />
                  <ExcelColumn label="1 Bairro" value="1bairro" />
                  <ExcelColumn label="2 Entrega" value="2entrega" />
                  <ExcelColumn label="2 Valor" value="2valor" />
                  <ExcelColumn label="2 Bairro" value="2bairro" />
                  <ExcelColumn label="3 Entrega" value="3entrega" />
                  <ExcelColumn label="3 Valor" value="3valor" />
                  <ExcelColumn label="3 Bairro" value="3bairro" />
                  <ExcelColumn label="4 Entrega" value="4entrega" />
                  <ExcelColumn label="4 Valor" value="4valor" />
                  <ExcelColumn label="4 Bairro" value="4bairro" />
                </ExcelSheet>
              </ExcelFile>
            </div>
          ) : (
            <ColorButton
              variant="contained"
              color="primary"
              style={{ width: '30%', alignSelf: 'center' }}
              onClick={async () => {
                await handleBuildCSV();
              }}
            >
              Gerar Relatório
            </ColorButton>
          )}
        </>
      )}
    </Container>
  );
};
export default Extract;
