import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  margin: 0 auto;
  max-width: 90%;

  header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    padding: 0 50px;

    strong {
      font-size: 1.562rem;
      font-weight: bold;
    }

    button {
      width: 200px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 25px;

  span {
    margin-left: 25px;
  }
`;

export const List = styled.span`
  flex: 1;
  height: 69vh;
  width: 210px;
  min-width: 210px;
  max-width: 18%;
  margin-top: 20px;
  padding: 20px;
  background: #ededed;
  border-radius: 13px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0;
  }

  > strong {
    color: black;
  }

  div + div:hover {
    opacity: 0.78;
  }
`;

export const ColorButton = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: '#49717A',
    '&:hover': {
      backgroundColor: '#334f55',
    },
  },
}))(Button);
