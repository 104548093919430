import React, { useEffect, useState, useCallback } from 'react';
import { formatISO, parseISO } from 'date-fns';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import OrderDetail from './OrderDetails';
import RateModal from '../../../components/RateModal';

import { Container, FirstColumn, SecondColumn } from './styles';

interface Order {
  id: string;
  status: string;
  user: { name: string };
  deliveryman: { name: string };
  deliveries: {
    id: string;
    status: string;
    neighborhood: {
      name: string;
    };
    price: number;
  }[];
  createdAt: string;
  updatedAt: string;
  deliverymanEvaluation: {
    rating: number;
  };
}
interface OrderCardProps {
  order: Order;
  updateOrder(order: Order): void;
}

const RateDelivery = gql`
  mutation EvaluateDeliveryman($data: EvaluateDeliverymanInput!) {
    EvaluateDeliveryman(data: $data) {
      deliverymanEvaluation {
        id
        rating
      }
    }
  }
`;

const OrderCard: React.FC<OrderCardProps> = ({ order, updateOrder }) => {
  const [formattedTime, setFormattedTime] = useState('');
  const [open, setOpen] = useState(false);
  const [openRate, setOpenRate] = useState(false);
  const [evaluateDeliveryman] = useMutation(RateDelivery);

  const handleEvaluateOrder = useCallback(
    async (rating, comment) => {
      await evaluateDeliveryman({
        variables: { data: { deliveryOrderId: order.id, rating, comment } },
      });

      updateOrder({ ...order, deliverymanEvaluation: { rating } });
    },
    [evaluateDeliveryman, order, updateOrder],
  );

  useEffect(() => {
    const time = formatISO(parseISO(order.createdAt), {
      representation: 'time',
    });
    setFormattedTime(`${time.split(':')[0]}:${time.split(':')[1]}`);
  }, [order.createdAt]);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleRateClick = useCallback((e) => {
    e.stopPropagation();
    setOpenRate(true);
  }, []);

  return (
    <>
      <Container onClick={() => handleClick()}>
        <FirstColumn>
          <div>
            <strong>{`#${order.id}`}</strong>
          </div>
          <strong>Cliente</strong>
          <p>{order.user.name}</p>
        </FirstColumn>
        <SecondColumn>
          <p>{`${formattedTime}`}</p>
          {order.status !== 'CANCELED' && (
            <>
              <strong>Entregador</strong>
              {order.deliveryman ? (
                <p>{order.deliveryman.name}</p>
              ) : (
                <p>Procurando...</p>
              )}
            </>
          )}
          {order.status === 'FINISHED' && !order.deliverymanEvaluation && (
            <button onClick={handleRateClick}>AVALIAR</button>
          )}
          {order.deliverymanEvaluation && (
            <p>{order.deliverymanEvaluation.rating}</p>
          )}
        </SecondColumn>
      </Container>
      <RateModal
        open={openRate}
        setClose={() => {
          setOpenRate(false);
        }}
        handleEvaluate={handleEvaluateOrder}
        order={order}
      />
      <OrderDetail
        open={open}
        setClose={() => {
          setOpen(false);
        }}
        order={order}
      />
    </>
  );
};

export default OrderCard;
