import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { FiLogIn, FiMail } from 'react-icons/fi';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { useMutation } from '@apollo/react-hooks';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.svg';
import { RESET_USER_PASSWORD } from '../../graphql/mutation';
import Input from '../../components/LoginInput';
import Button from '../../components/Button';

import { Container, Content, Background } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const RecoverPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const [resetUserPassword] = useMutation(RESET_USER_PASSWORD);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail é obrigatorio')
            .email('Digite um e-mail válido'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await resetUserPassword({
          variables: {
            data: { email: data.email.toLowerCase() },
          },
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Erro na enviar email',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [resetUserPassword, addToast],
  );

  return (
    <Container>
      <Content>
        <img src={logoImg} alt="TaNaMao" />

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input name="email" icon={FiMail} placeholder="E-mail" />

          <Button type="submit">Entrar</Button>

          {/* <a href="forgot">Esqueci minha senha</a> */}
        </Form>

        <Link to="/" style={{ marginBottom: 10 }}>
          Efetuar login
        </Link>

        <Link to="/signup">
          <FiLogIn />
          Criar uma conta
        </Link>
      </Content>
      <Background />
    </Container>
  );
};
export default RecoverPassword;
