import React, { useState, useCallback, useEffect } from 'react';

import {
  Dialog,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { ColorButton, Container } from './styles';
import client from '../../services/graphqlClient';
import { useToast } from '../../hooks/toast';

const AddCity = gql`
  mutation($data: CityCreateInput!) {
    createOneCity(data: $data) {
      id
      name
    }
  }
`;

const GetStates = gql`
  query($where: StateWhereInput) {
    states(where: $where, orderBy: { name: asc }) {
      id
      name
    }
  }
`;

interface StateModalProps {
  setOpen: any;
  open: boolean;
  getCities: () => Promise<void>;
}

interface State {
  id: string;
  name: string;
}

const CityModal: React.FC<StateModalProps> = ({ setOpen, open, getCities }) => {
  const [nameInput, setNameInput] = useState('');
  const [states, setStates] = useState<State[]>([]);
  const [stateId, setStateId] = useState('');
  const { addToast } = useToast();

  const createCity = useCallback(async () => {
    try {
      await client.mutate({
        mutation: AddCity,
        variables: {
          data: {
            name: nameInput,
            state: {
              connect: {
                id: stateId,
              },
            },
          },
        },
      });
      await getCities();
      setOpen(false);
      addToast({
        type: 'success',
        title: 'Cadastro realizado com sucesso',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: 'Ops, a cidade não pode ser cadastrado',
      });
    }
  }, [nameInput, stateId, addToast, setOpen, getCities]);

  const getStates = useCallback(async () => {
    try {
      const { data } = await client.query({
        query: GetStates,
      });
      setStates(data.states);
    } catch (error) {
      console.log('asd');
    }
  }, []);

  useEffect(() => {
    getStates();
  }, []); // eslint-disable-line

  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{ style: { maxWidth: '100%' } }}
    >
      <Container>
        <TextField
          style={{ flex: 1, marginBottom: 15 }}
          variant="outlined"
          required
          fullWidth
          value={nameInput}
          onChange={(e) => setNameInput(e.target.value)}
          label="Nome da cidade"
        />
        <FormControl
          variant="outlined"
          style={{ width: '100%', marginBottom: 15 }}
        >
          <InputLabel id="demo-simple-select-filled-label">Estado</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            onChange={(event: any) => {
              setStateId(event.target.value);
            }}
            value={stateId}
          >
            {states.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ColorButton
          variant="contained"
          color="primary"
          style={{ height: 50, flex: 1 }}
          onClick={createCity}
        >
          Cadastrar
        </ColorButton>
      </Container>
    </Dialog>
  );
};

export default CityModal;
