import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Dialog from '@material-ui/core/Dialog';
import { MdEdit, MdCancel } from 'react-icons/md';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { get } from 'lodash';
import { useToast } from '../../../hooks/toast';
import { ACCOUNT_STATUS } from '../../../utils/constants';

import Button from '../../../components/Button';
import Select from '../../../components/Select';

import { Container, Item, Divider, Options } from './styles';

interface UserItem {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
  phone: string;
  documentType: string;
  document: string;
  role: string;
  accountStatus: string;
  address: {
    zipcode: string;
    city: {
      name: string;
    };
    state: {
      name: string;
    };
    neighborhood: {
      name: string;
    };
    street: string;
    number: string;
  };
  createdAt: string;
}

interface UserDetailsProps {
  open: boolean;
  setClose(): void;
  item: UserItem;
  update(id: string, newStatus: string): void;
}

interface FormSubmit {
  accountStatus: string;
}

const UpdateUserRequest = gql`
  mutation updateOneUser(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    updateOneUser(data: $data, where: $where) {
      id
    }
  }
`;

const UserDetails: React.FC<UserDetailsProps> = ({
  open,
  setClose,
  item,
  update,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [updateOneUser] = useMutation(UpdateUserRequest);
  const [disabled, setDisabled] = useState(true);
  const { addToast } = useToast();

  const statusOptions = [
    { value: 'PENDING', label: 'Pendente' },
    { value: 'APPROVED', label: 'Aprovado' },
    { value: 'RECUSED', label: 'Recusado' },
    { value: 'SUSPENDED', label: 'Suspenso' },
  ];

  const handleSubmit = useCallback(
    async (data: FormSubmit) => {
      try {
        const { accountStatus } = data;

        console.log(accountStatus);

        await updateOneUser({
          variables: { data: { accountStatus }, where: { id: item.id } },
        });

        addToast({
          type: 'success',
          title: 'Cliente editado com sucesso.',
        });
        update(item.id, accountStatus);
        setDisabled(true);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao editar cliente.',
        });
      }
    },
    [updateOneUser, addToast, item.id, update],
  );

  return (
    <Dialog
      onClose={() => {
        setClose();
      }}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Container>
        <Item>
          <h1>Cliente: </h1>
          <strong>{item.name}</strong>
        </Item>
        <Item>
          <h1>Email:</h1>
          <strong>{item.email}</strong>
        </Item>
        <Item>
          <h1>Tipo de documento: </h1>
          <strong>{item.documentType}</strong>
        </Item>
        <Item>
          <h1>Número do documento: </h1>
          <strong>{item.document}</strong>
        </Item>
        <Item>
          <h1>Telefone: </h1>
          <strong>{item.phone}</strong>
        </Item>
        <Item>
          <h1>Endereço: </h1>
          <strong>
            {get(item, 'address.street', '')}{' '}
            {get(item, 'address.neighborhood.name', '')}{' '}
            {get(item, 'address.number')}, {get(item, 'address.city.name', '')}{' '}
            - {get(item, 'address.state.name', '')}
          </strong>
        </Item>

        <Divider />
        <div>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Options>
              {disabled ? (
                <button
                  type="button"
                  onClick={() => {
                    setDisabled(false);
                  }}
                >
                  <MdEdit color="#4D85EE" size={19} />
                </button>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <button
                    type="button"
                    onClick={() => {
                      setDisabled(true);
                    }}
                  >
                    <MdCancel
                      style={{ marginRight: '5px' }}
                      color="red"
                      size={19}
                    />
                  </button>
                </div>
              )}
            </Options>
            <Select
              name="accountStatus"
              isDisabled={disabled}
              label="Status"
              options={statusOptions}
              defaultValue={{
                value: item.accountStatus,
                label: ACCOUNT_STATUS[item.accountStatus],
              }}
            />
            {!disabled ? <Button type="submit">Editar</Button> : null}
          </Form>
        </div>
        {/* <div>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Options>
              {disabled ? (
                <button
                  type="button"
                  onClick={() => {
                    setDisabled(false);
                  }}
                >
                  <MdEdit color="#4D85EE" size={19} />
                </button>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <button
                    type="button"
                    onClick={() => {
                      setDisabled(true);
                    }}
                  >
                    <MdCancel
                      style={{ marginRight: '5px' }}
                      color="red"
                      size={19}
                    />
                  </button>
                </div>
              )}
            </Options>
            <Select
              name="accountStatus"
              isDisabled={disabled}
              label="Status"
              options={statusOptions}
              defaultValue={{
                value: item.accountStatus,
                label: item.accountStatus,
              }}
            />
            {!disabled ? <Button type="submit">Editar</Button> : null}
          </Form>
        </div> */}
      </Container>
    </Dialog>
  );
};

export default UserDetails;
