import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 2.5em;
  justify-content: center;
  align-items: center;
`;
