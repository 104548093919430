import React, { useState, useCallback } from 'react';

import { Dialog, TextField } from '@material-ui/core';
import gql from 'graphql-tag';
import { ColorButton, Container } from './styles';
import client from '../../services/graphqlClient';
import { useToast } from '../../hooks/toast';
import { State } from '../../utils/interfaces';

interface StateModalProps {
  setOpen: any;
  open: boolean;
  item?: State;
}

const AddState = gql`
  mutation($data: StateCreateInput!) {
    createOneState(data: $data) {
      id
      name
    }
  }
`;

const EDIT_STATE = gql`
  mutation($data: StateCreateInput!, $where: StateWhereUniqueInput!) {
    updateOneState(data: $data, where: $where) {
      id
      name
    }
  }
`;

const StateModal: React.FC<StateModalProps> = ({
  setOpen,
  open,
  item = null,
}) => {
  const [nameInput, setNameInput] = useState(item?.name || '');
  const [abbreviationInput, setAbbreviationInput] = useState(
    item?.abbreviation || '',
  );

  const { addToast } = useToast();

  const createState = useCallback(async () => {
    try {
      await client.mutate({
        mutation: AddState,
        variables: {
          data: {
            name: nameInput,
            abbreviation: abbreviationInput,
          },
        },
      });
      setOpen(false);
      addToast({
        type: 'success',
        title: 'Cadastro realizado com sucesso',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: 'Ops, o estado não pode ser cadastrado',
      });
    }
  }, [nameInput, abbreviationInput, addToast, setOpen]);

  const editState = useCallback(async () => {
    try {
      await client.mutate({
        mutation: EDIT_STATE,
        variables: {
          data: {
            name: nameInput,
            abbreviation: abbreviationInput,
          },
          where: {
            id: item?.id,
          },
        },
      });
      setOpen(false);
      addToast({
        type: 'success',
        title: 'Estado editado com sucesso',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na edição',
        description: 'Ops, o estado não pode ser editado',
      });
    }
  }, [nameInput, abbreviationInput, addToast, setOpen, item]);

  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{ style: { maxWidth: '100%' } }}
    >
      <Container>
        <TextField
          style={{ flex: 1, marginBottom: 15 }}
          variant="outlined"
          required
          fullWidth
          value={nameInput}
          onChange={(e) => setNameInput(e.target.value)}
          label="Nome do estado"
        />
        <TextField
          style={{ flex: 1, marginBottom: 15 }}
          variant="outlined"
          required
          fullWidth
          value={abbreviationInput}
          onChange={(e) => setAbbreviationInput(e.target.value)}
          label="Sigla"
        />
        <ColorButton
          variant="contained"
          color="primary"
          style={{ height: 50, flex: 1 }}
          onClick={item ? editState : createState}
        >
          Cadastrar
        </ColorButton>
      </Container>
    </Dialog>
  );
};

export default StateModal;
