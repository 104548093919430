import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { format, parseISO } from 'date-fns';

import { applyPriceMask } from '../../../utils/priceMask.js';

import { Container } from './styles';

interface ExtractItem {
  id: string;
  user: { name: string };
  deliveryman: { name: string };
  deliveries: {
    id: string;
    status: string;
    neighborhood: {
      name: string;
    };
    price: number;
  }[];
  createdAt: string;
  updatedAt: string;
}

interface ExtractDetailsProps {
  open: boolean;
  setClose(): void;
  item: ExtractItem;
}

const ExtractDetails: React.FC<ExtractDetailsProps> = ({
  open,
  setClose,
  item,
}) => {
  return (
    <Dialog
      onClose={() => setClose()}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Container>
        <div>
          <h1>ID: </h1>
          <strong>{item.id}</strong>
        </div>
        <div>
          <h1>Cliente:</h1>
          <strong>{item.user?.name}</strong>
        </div>
        <div>
          <h1>Entregador: </h1>
          <strong>{item.deliveryman?.name}</strong>
        </div>
        <div>
          <h1>Data & Hora:</h1>
          <strong>
            {format(parseISO(item.createdAt), 'dd/MM/yyyy hh:mm')}
          </strong>
        </div>
        {item.deliveries.map((delivery, idx) => (
          <span key={delivery.id}>
            <h1>{`Entrega ${idx + 1}`}</h1>
            <div>
              <h1>ID:</h1>
              <strong>{delivery.id}</strong>
            </div>
            <div>
              <h1>Bairro:</h1>
              <strong>{delivery.neighborhood.name}</strong>
            </div>
            <div>
              <h1>Valor:</h1>
              <strong>{applyPriceMask(delivery.price)}</strong>
            </div>
          </span>
        ))}
      </Container>
    </Dialog>
  );
};

export default ExtractDetails;
