import React, { useState, useCallback, useEffect, useRef } from 'react';
import { MdMoreHoriz, MdRemoveRedEye } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import { applyPriceMask } from '../../../utils/priceMask.js';

import ExtractDetails from '../ExtractDetails';

import {
  Container,
  FirstItem,
  LastItem,
  Badge,
  OptionsList,
  OptionsContainer,
} from './styles';

interface ExtractItem {
  item: {
    id: string;
    user: { name: string };
    deliveryman: { name: string };
    deliveries: {
      id: string;
      status: string;
      neighborhood: {
        name: string;
      };
      price: number;
    }[];
    createdAt: string;
    updatedAt: string;
  };
}

const ExtractItem: React.FC<ExtractItem> = ({ item }) => {
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);

  const handleClickOutside = useCallback((event: any) => {
    if (ref.current && !ref.current.contains(event.target)) setVisible(false);
  }, []);

  const handleToggleVisible = useCallback(() => {
    setVisible((state) => !state);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleTotalPrice = (): number => {
    let total = 0;
    item.deliveries.forEach((delivery) => {
      total += delivery.price;
    });

    return total;
  };

  return (
    <Container>
      <td>
        <FirstItem>{`#${item.id}`}</FirstItem>
      </td>
      <td>
        <div>
          <p>{format(parseISO(item.createdAt), 'dd/MM/yyyy hh:mm')}</p>
        </div>
      </td>
      <td>
        <div>
          <p>{item.user?.name}</p>
        </div>
      </td>
      <td>
        <div>
          <p>{item.deliveryman?.name}</p>
        </div>
      </td>
      <td>
        <div>
          <p>{applyPriceMask(handleTotalPrice())}</p>
        </div>
      </td>
      <td>
        <LastItem>
          <OptionsContainer ref={ref}>
            <Badge onClick={handleToggleVisible}>
              <MdMoreHoriz color="#C6C6C6" size={25} />
            </Badge>
            <OptionsList visible={visible}>
              <span>
                <button
                  type="button"
                  onClick={() => {
                    setOpen(true);
                    setVisible(false);
                  }}
                >
                  <MdRemoveRedEye color="#4D85EE" size={16} />
                  <p>Status</p>
                </button>
              </span>
            </OptionsList>
          </OptionsContainer>
        </LastItem>
      </td>
      <ExtractDetails open={open} setClose={() => setOpen(false)} item={item} />
    </Container>
  );
};

export default ExtractItem;
