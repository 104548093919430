import React from 'react';

import get from 'lodash/get';
import { Container, Row, Photo, Info, Column } from './styles';
import { Deliveryman } from '../../../../utils/interfaces';
import STATUS from '../../../../utils/status';
import Button from '../../../Button';

interface DeliveryManMapMarker {
  deliveryManMapMarker: {
    id: number;
    deliveryman: Deliveryman;
    order: any;
  };
  onOffline(id: string): Promise<void>;
  onBlockAccount(id: string): Promise<void>;
}

const MarkerInfo: React.FC<DeliveryManMapMarker> = ({
  deliveryManMapMarker,
  onOffline,
  onBlockAccount,
}) => {
  return (
    <Container>
      <Row>
        <Photo src={get(deliveryManMapMarker.deliveryman, 'avatarUrl', '')} />
        <Column>
          <Info>
            <strong>Nome: </strong>
            {deliveryManMapMarker.deliveryman.name}
          </Info>
          <Info>
            <strong>Cidade: </strong>
            {deliveryManMapMarker.deliveryman.city.name}
          </Info>
          <Info>
            <strong>Status: </strong>
            {STATUS[deliveryManMapMarker.deliveryman.status]}
          </Info>
          <Info>
            <strong>Status da conta: </strong>
            {deliveryManMapMarker.deliveryman.accountStatus}
          </Info>
          {deliveryManMapMarker.order && (
            <Info>
              <strong>Loja: </strong>
              {deliveryManMapMarker.order.user.name}
            </Info>
          )}
          {deliveryManMapMarker.deliveryman.status === 'ONLINE' && (
            <Button
              type="button"
              onClick={() => onOffline(deliveryManMapMarker.deliveryman.id)}
            >
              Offline
            </Button>
          )}
          {deliveryManMapMarker.deliveryman.accountStatus === 'APPROVED' && (
            <Button
              type="button"
              onClick={() =>
                onBlockAccount(deliveryManMapMarker.deliveryman.id)
              }
            >
              Bloquear
            </Button>
          )}
        </Column>
      </Row>
    </Container>
  );
};

export default MarkerInfo;
