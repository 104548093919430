import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

export const ColorButton = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: '#49717A',
    '&:hover': {
      backgroundColor: '#334f55',
    },
  },
}))(Button);

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 45%;
  align-self: center;
  margin: 20px;
`;
