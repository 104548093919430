import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 2.5em;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  h1 {
    font-size: 1rem;
    color: #444444;
  }

  span {
    display: flex;
    flex-direction: column;

    > h1:first-child {
      align-self: center;
      margin-bottom: 5px;
    }
  }

  strong {
    font-size: 1rem;
    margin-left: 5px;
    color: #666666;
  }
`;
