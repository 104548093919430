import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 2.5em;

  p {
    font-size: 1.125rem;
    font-weight: bold;
    text-align: center;
    color: #436461;
  }
`;

export const ControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5em;

  button {
    opacity: 0.8;
    border: 0;
    background: transparent;
    color: green;
    transition: opacity 0.1s;

    &:hover {
      opacity: 0.6;
    }

    &:disabled {
      color: gray;
      opacity: 0.8;
      cursor: default;
    }
  }

  button + button {
    color: red;
  }
`;
