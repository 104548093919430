import React, { useState, useRef, useCallback, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import gql from 'graphql-tag';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { getDistance } from 'geolib';
import { get, sortBy } from 'lodash';

import { useToast } from '../../../hooks/toast';
import GenericInput from '../../../components/GenericInput';
import Select from '../../../components/Select';
import Button from '../../../components/Button';
import { useAuth } from '../../../hooks/auth';
import { applyPriceMask } from '../../../utils/priceMask.js';

import { Container, ControlContainer } from './styles';

interface NewOrderFormProps {
  open: boolean;
  setClose(): void;
}

interface FormSubmit {
  comment: string;
  deliveries: {
    neighborhoodId: number;
    comment: string;
  }[];
}

interface INeighborhood {
  id: number;
  name: string;
  cityId: number;
  geolocation: {
    latitude: string;
    longitude: string;
  };
}

const DeliveryRequest = gql`
  mutation createDeliveryOrder(
    $comment: String!
    $deliveries: [DeliveryInputObject!]!
  ) {
    createDeliveryOrder(data: { comment: $comment, deliveries: $deliveries }) {
      deliveryOrder {
        id
      }
    }
  }
`;

const getNeighborhoodsRequest = gql`
  query getNeighborhoods($where: NeighborhoodWhereInput) {
    neighborhoods(where: $where) {
      id
      name
      cityId
      geolocation {
        latitude
        longitude
      }
    }
  }
`;

const NewOrderForm: React.FC<NewOrderFormProps> = ({ open, setClose }) => {
  const formRef = useRef<FormHandles>(null);
  const [deliveryRequest, { loading }] = useMutation(DeliveryRequest);
  const [formCount, setFormCount] = useState(0);
  const [forms, setForms] = useState<JSX.Element[]>([]);
  const { addToast } = useToast();
  const [neighborhoods, setNeighborhoods] = useState([]);
  const { user } = useAuth();
  const [getNeighborhoods] = useLazyQuery(getNeighborhoodsRequest, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const userLocation = {
        latitude: parseFloat(user.address?.geolocation.latitude as string),
        longitude: parseFloat(user.address?.geolocation.longitude as string),
      };

      const neighborhoodsWithPrice = response.neighborhoods.map(
        (item: INeighborhood) => {
          const {
            geolocation: { latitude, longitude },
          } = item;

          const neighborhoodLocation = {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
          };

          const distance = getDistance(userLocation, neighborhoodLocation);

          let price;

          if (distance < 4000) {
            price = 600;
          } else if (distance < 5000) {
            price = 700;
          } else if (distance < 6000) {
            price = 800;
          } else if (distance < 7000) {
            price = 900;
          } else if (distance < 8000) {
            price = 1000;
          } else if (distance < 9000) {
            price = 1100;
          } else if (distance < 10000) {
            price = 1200;
          } else if (distance < 11000) {
            price = 1300;
          } else if (distance < 12000) {
            price = 1400;
          } else {
            price = 1500;
          }

          return {
            ...item,
            name:
              item.cityId === 3
                ? item.name
                : `${item.name} - ${applyPriceMask(price)}`,
          };
        },
      );

      setNeighborhoods(neighborhoodsWithPrice);
    },
  });

  const handleSubmit = useCallback(
    async (data: FormSubmit) => {
      try {
        const { comment, deliveries } = data;

        await deliveryRequest({
          variables: { comment, deliveries },
        });
        setClose();

        addToast({
          type: 'success',
          title: 'Entrega cadastrada com sucesso.',
          description: 'Verifique os status no quadro de entregas.',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, um momento',
          description: 'Não foi possível cadastrar a entrega',
        });
      }
    },
    [deliveryRequest, addToast, setClose],
  );

  const orderForm = useCallback(
    (index: number) => (
      <div key={index} style={{ marginBottom: '15px' }}>
        <p style={{ marginBottom: '10px' }}>{`Pedido ${index + 1}`}</p>
        <Select
          label="Bairro *"
          name={`deliveries[${index}].neighborhoodId`}
          placeholder="Selecione o bairro"
          options={
            neighborhoods
              ? sortBy(
                  neighborhoods.map((neighborhood) => ({
                    label: get(neighborhood, 'name', ''),
                    value: get(neighborhood, 'id', ''),
                  })),
                )
              : []
          }
        />
        <GenericInput
          label="Complemento"
          name={`deliveries[${index}].comment`}
          placeholder="Ex: Rua ..."
        />
      </div>
    ),
    [neighborhoods],
  );

  useEffect(() => {
    getNeighborhoods({
      variables: {
        where: {
          cityId: {
            equals: get(user, 'address.cityId', ''),
          },
        },
      },
    });
    setForms(() => {
      const newForm = [];
      let i = 0;
      for (i; i <= formCount; i += 1) {
        newForm.push(orderForm(i));
      }
      return newForm;
    });
  }, [formCount, orderForm, user, getNeighborhoods]);

  useEffect(() => {
    setFormCount(0);
  }, [open]);

  return (
    <Dialog
      onClose={() => setClose()}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <GenericInput
            label="Comentario"
            name="comment"
            placeholder="Adicione um comentário"
          />
          <ControlContainer>
            <button
              onClick={() => setFormCount((state) => state + 1)}
              type="button"
              disabled={formCount === 3}
            >
              <FiPlusCircle size={30} />
            </button>
            <button
              onClick={() => setFormCount((state) => state - 1)}
              type="button"
              disabled={formCount === 0}
            >
              <FiMinusCircle size={30} />
            </button>
          </ControlContainer>

          {forms.map((item: JSX.Element) => item)}

          <Button disabled={loading} type="submit">
            Cadastrar Entrega
          </Button>
        </Form>
      </Container>
    </Dialog>
  );
};

export default NewOrderForm;
