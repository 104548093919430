import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  height: 100%;

  form {
    padding: 38px 120px;
    display: flex;
    margin: 0 auto;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 102px;
    width: 86px;
  }

  strong {
    font-size: 2rem;
  }
`;

export const FormCard = styled.div`
  background: #fff;
  width: 500px;
  height: fit-content;
  padding: 40px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;

  a {
    color: #436461;
    display: block;
    margin-top: 20px;
    display: flex;
    align-items: center;
    align-self: center;
    text-decoration: none;
    transition: background-color 0.2s;

    &:hover {
      color: ${shade(0.4, '#436461')};
    }

    svg {
      margin-right: 5px;
    }
  }
`;
