export const ACCOUNT_STATUS = {
  PENDING: 'Pendente',
  APPROVED: 'Aprovado',
  RECUSED: 'Recusado',
  SUSPENDED: 'Suspenso',
};

export const VENICHE_TYPE = {
  BICYCLE: 'Bicicleta',
  CAR: 'Carro',
  MOTOCYCLE: 'Motocicleta',
};

export const ROLE_TYPE = {
  ADMIN: 'Administrador',
  CITY_ADMIN: 'Admin da cidade',
  DEFAULT: 'Usuário',
};
