import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: #fff;
  flex: 1;
  justify-content: space-between;
  margin-top: 13px;
  border-radius: 13px;
  padding: 14px 14px 18px 14px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;

export const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    justify-content: center;
    color: black;
    background: #d6d6d6;
    padding: 5px 10px;
    border-radius: 8px;
    margin-bottom: 14px;
  }

  strong {
    font-size: 0.812rem;
    color: black;
  }

  p {
    margin-top: 8px;
    font-size: 0.687rem;
    color: #575656;
  }
`;

export const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p {
    color: #30d2c3;
    font-size: 0.812rem;
  }

  strong {
    margin-top: 23px;
    font-size: 0.812rem;
    color: black;
  }

  strong + p {
    margin-top: 8px;
    font-size: 0.687rem;
    color: #575656;
  }

  button {
    background-color: #4a717a;
    border-radius: 5px;
    margin-top: 10px;
    padding: 5px 10px;
    border-width: 0;
    color: #fff;
    font-size: 15px;
  }
`;
