import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === 'production'
      ? 'https://test-api.tanamaoentregas.com/'
      : 'http://localhost:4000/',
  // ? 'https://api-staging.tanamaoentregas.com/'
  // : 'https://api-staging.tanamaoentregas.com/',
});

const wsLink = new WebSocketLink({
  uri:
    process.env.NODE_ENV === 'production'
      ? 'wss://api-staging.tanamaoentregas.com/'
      : 'wss://api-staging.tanamaoentregas.com/',
  // : 'ws://localhost:4000/',
  options: { reconnect: true },
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('@TaNaMao:token');

  return {
    headers: {
      ...headers,
      Authorization: token != null ? `Bearer ${token}` : '',
    },
  };
});

const httpAuthLink = authLink.concat(httpLink);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpAuthLink,
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
