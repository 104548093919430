import gql from 'graphql-tag';

export const GET_DELIVERYMEN_ORDERS = gql`
  query deliverymenWithOrders(
    $where: DeliverymanWhereInput
    $whereOrder: DeliveryOrderWhereInput
  ) {
    deliverymen(where: $where) {
      id
      name
      avatarUrl
      status
      accountStatus
      city {
        name
      }
      geolocation {
        latitude
        longitude
      }
    }

    deliveryOrders(where: $whereOrder) {
      id
      status
      user {
        name
      }
      deliveryman {
        id
        name
      }
      deliveries {
        id
        status
        neighborhood {
          name
        }
        price
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_STATES = gql`
  query(
    $where: StateWhereInput
    $after: StateWhereUniqueInput
    $before: StateWhereUniqueInput
    $first: Int
    $last: Int
  ) {
    cities(
      where: $where
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: { name: asc }
    ) {
      id
      name
    }
  }
`;

export const GET_USER_REQUEST = gql`
  query user($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      name
      role
      address {
        zipcode
        cityId
        stateId
        neighborhoodId
        geolocation {
          latitude
          longitude
        }
        street
        number
      }
      document
      documentType
      phone
      email
    }
  }
`;

export const GET_DELIVERYMAN = gql`
  query deliveryman($where: DeliverymanWhereUniqueInput!) {
    deliveryman(where: $where) {
      id
      name
      phone
      email
      cityId
      stateId
      avatarUrl
      cpf
      veniche {
        plate
        brand
        model
        color
        crlvUrl
      }
      documentUrl
      proofOfAddressUrl
    }
  }
`;
