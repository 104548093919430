import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  max-width: 90%;
  padding: 15px 0;
  margin: 0 auto;
  flex-direction: column;
`;

export const ExtractList = styled.table`
  margin-top: 22px;
  border-collapse: collapse;
  width: 100%;
`;

export const ItemTitle = styled.tr`
  th:last-child {
    padding-right: 13px;
    text-align: right;
  }
  th:first-child {
    padding-left: 0.81rem;
  }
  th {
    font-size: 1rem;
    text-align: left;
    padding-bottom: 0.875rem;
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PaginationContainer = styled.div`
  margin: 0 auto;
  display: flex;

  div {
    background: #fff;
    color: #303030;
    width: fit-content;
    margin: 0 10px;
    padding: 4px;
  }

  button {
    background: none;
    border: 0;
    color: #fff;

    &:hover {
      color: ${darken(0.1, '#fff')};
    }

    &:disabled {
      color: gray;
    }
  }
`;
