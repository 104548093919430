import React, { useCallback, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Rating } from '@material-ui/lab';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import { TextField, Typography } from '@material-ui/core';

import { useToast } from '../../hooks/toast';

import Button from '../Button';

import { Container } from './styles';

interface OrderItem {
  id: string;
}

interface OrderDetailProps {
  open: boolean;
  setClose(): void;
  order: OrderItem;
  handleEvaluate(rating: number, comment: string): Promise<void>;
}

const OrderDetail: React.FC<OrderDetailProps> = ({
  open,
  setClose,
  handleEvaluate,
}) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const { addToast } = useToast();

  const handleEvaluateOrder = useCallback(async () => {
    try {
      if (rating === 0) {
        addToast({
          type: 'error',
          title: 'Ops, um momento.',
          description: 'Escolha de 1 a 5 estrelas para classificar!',
        });
      }

      await handleEvaluate(rating, comment);

      addToast({
        type: 'success',
        title: 'Avaliação feita com sucesso.',
        description: 'Você pode conferir sua avaliação no quadro de entregas.',
      });
      setClose();
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Ops, um momento.',
        description: 'Não foi possível avaliar a entrega.',
      });
    } finally {
      setClose();
    }
  }, [addToast, setClose, rating, comment, handleEvaluate]);

  return (
    <Dialog
      open={open}
      onClose={() => setClose()}
      aria-labelledby="simple-dialog-title"
    >
      <Container>
        <Typography component="legend">
          Como foi sua experiencia? Escolha de 1 a 5 estrelas para classificar!
        </Typography>

        <Rating
          style={{ marginBottom: 20 }}
          value={rating}
          name="customized-empty"
          size="large"
          defaultValue={2}
          emptyIcon={<StarBorderIcon fontSize="inherit" />}
          onChange={(event, newValue) => {
            setRating(newValue || 0);
          }}
        />

        <TextField
          style={{ width: '100%' }}
          label="Comentário"
          id="outlined-size-normal"
          defaultValue="Normal"
          variant="outlined"
          value={comment}
          onChange={(event) => {
            setComment(event.target.value);
          }}
        />
        <Button style={{ background: 'green' }} onClick={handleEvaluateOrder}>
          Avaliar
        </Button>
      </Container>
    </Dialog>
  );
};

export default OrderDetail;
