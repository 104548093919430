import React, { useState, useCallback, useRef, useEffect } from 'react';
import { MdMoreHoriz, MdRemoveRedEye } from 'react-icons/md';

import gql from 'graphql-tag';
import { NeighborhoodModal } from '../../../components';

import {
  Container,
  FirstItem,
  LastItem,
  Badge,
  OptionsList,
  OptionsContainer,
} from './styles';
import client from '../../../services/graphqlClient';
import { useToast } from '../../../hooks/toast';

const UpdateNeighborhood = gql`
  mutation(
    $data: NeighborhoodUpdateInput!
    $where: NeighborhoodWhereUniqueInput!
  ) {
    updateOneNeighborhood(data: $data, where: $where) {
      id
      name
    }
  }
`;

interface NeighborhoodProps {
  item: {
    id: string;
    name: string;
    city: {
      id: string;
      name: string;
      state: {
        id: string;
        name: string;
      };
    };
    geolocation: {
      latitude: string;
      longitude: string;
    };
  };
  getNeighborhoods: (dataParams?: any) => Promise<void>;
  cities: any;
}
const CitiesItem: React.FC<NeighborhoodProps> = ({
  item,
  getNeighborhoods,
  cities,
}) => {
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);
  const { addToast } = useToast();

  const handleClickOutside = useCallback((event: any) => {
    if (ref.current && !ref.current.contains(event.target)) setVisible(false);
  }, []);

  const handleToggleVisible = useCallback(() => {
    setVisible((state) => !state);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const updateNeighborhood = useCallback(
    async (name, cityId, latitude, longitude) => {
      try {
        await client.mutate({
          mutation: UpdateNeighborhood,
          variables: {
            data: {
              name,
              city: {
                connect: {
                  id: cityId,
                },
              },
              geolocation: {
                create: {
                  latitude,
                  longitude,
                },
              },
            },
            where: {
              id: item.id,
            },
          },
        });
        await getNeighborhoods({ where: { cityId: { equals: cityId } } });
        setOpen(false);
        addToast({
          type: 'success',
          title: 'Atualização realizado com sucesso',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ops, o bairro não pode ser atualizado',
        });
      }
    },
    [getNeighborhoods, addToast, item],
  );

  return (
    <Container>
      <td>
        <FirstItem>{item.name}</FirstItem>
      </td>
      <td>
        <div>
          <p>{item.city.name}</p>
        </div>
      </td>
      <td>
        <div>
          <p>{item.city.state.name}</p>
        </div>
      </td>
      <td>
        <LastItem>
          <OptionsContainer ref={ref}>
            <Badge onClick={() => handleToggleVisible()}>
              <MdMoreHoriz color="#C6C6C6" size={25} />
            </Badge>
            <OptionsList visible={visible}>
              <span>
                <button
                  type="button"
                  onClick={() => {
                    setOpen(true);
                    setVisible(false);
                  }}
                >
                  <MdRemoveRedEye color="#4D85EE" size={16} />
                  <p>Editar</p>
                </button>
              </span>
              {/* <span>
                <button
                  type="button"
                  onClick={() => {
                    handleToggleVisible();
                  }}
                >
                  <MdDeleteForever color="#DE3B3B" size={16} />
                  <p>Excluir</p>
                </button>
              </span> */}
            </OptionsList>
          </OptionsContainer>
        </LastItem>
      </td>
      <NeighborhoodModal
        setOpen={setOpen}
        open={open}
        cities={cities}
        handleNeighborhood={updateNeighborhood}
        nameNeighborhood={item.name}
        cityNeighborhood={item.city.id}
        geolocation={{
          latitude: item.geolocation.latitude,
          longitude: item.geolocation.longitude,
        }}
      />
    </Container>
  );
};

export default CitiesItem;
