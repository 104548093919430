import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import gql from 'graphql-tag';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { get, debounce } from 'lodash';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import getValidationErrors from '../../utils/getValidationErrors';
import logoImg from '../../assets/logo.svg';
import { useToast } from '../../hooks/toast';
import { GET_USER_REQUEST } from '../../graphql/query';
import Loading from '../../components/Loading';
import GenericInput from '../../components/GenericInput';
import MaskInput from '../../components/MaskInput';
import Select from '../../components/Select';
import Button from '../../components/Button';
import client from '../../services/graphqlClient';
import { useAuth } from '../../hooks/auth';

import { Container, Header, FormCard } from './styles';
import { CHANGE_USER_PASSWORD } from '../../graphql/mutation';

const UpdateUserRequest = gql`
  mutation updateOneUser(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    updateOneUser(data: $data, where: $where) {
      id
    }
  }
`;

const getStateRequest = gql`
  query getSate {
    states {
      id
      name
    }

    cities {
      id
      name
    }

    neighborhoods {
      id
      name
      cityId
    }
  }
`;

interface MarkerObj {
  lat: number;
  lng: number;
}

interface FormDTO {
  cep: string;
  state: number;
  city: number;
  neighborhood: number;
  street: string;
  number: string;
  complement?: string;
  cpf: string;
  name: string;
  phone: string;
  email: string;
  password?: string;
  passwordConfirm?: string;
}

interface SignInFormData {
  oldpassword: string;
  password: string;
}

const libraries = ['places'];

const EditUser: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const formPassRef = useRef<FormHandles>(null);
  const mapRef = useRef<any>(null);
  const [cpfOrCnpj, setCpfOrCnpj] = useState('CPF');
  const { user } = useAuth();

  const [open, setOpen] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialPosition, setInitialPosition] = useState<[number, number]>([
    0,
    0,
  ]);
  const [updateOneUser] = useMutation(UpdateUserRequest);
  const [marker, setMarker] = useState<MarkerObj | null>(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries,
  });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);

  const [cityFilter, setCityFilter] = useState(null);
  const [initialData, setInitialData] = useState<FormDTO>({} as FormDTO);

  const { id } = useParams<{ id: string }>();

  const [changeUserPassword] = useMutation(CHANGE_USER_PASSWORD);

  const onMapClick = useCallback((e) => {
    setMarker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }, []);

  const [getStates] = useLazyQuery(getStateRequest, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setStates(response.states);
      setCities(response.cities);
      setNeighborhoods(response.neighborhoods);
    },
  });

  const getInitialData = useCallback(async () => {
    setLoading(true);
    const { data: response } = await client.query({
      query: GET_USER_REQUEST,
      fetchPolicy: 'no-cache',
      variables: {
        where: {
          id: Number(id),
        },
      },
    });
    const { user } = response;

    if (user) {
      setCpfOrCnpj(user.documentType);
      setInitialPosition([
        Number(user.address.geolocation.latitude),
        Number(user.address.geolocation.longitude),
      ]);
      setMarker({
        lat: Number(user.address.geolocation.latitude),
        lng: Number(user.address.geolocation.longitude),
      });
      setInitialData({
        cep: user.address.zipcode,
        street: user.address.street,
        number: user.address.number,
        cpf: user.document,
        name: user.name,
        phone: user.phone,
        email: user.email,
        state: user.address.stateId,
        city: user.address.cityId,
        neighborhood: user.address.neighborhoodId,
      });
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getStates();
    getInitialData();
  }, [getStates, getInitialData]);

  const handleSubmit = useCallback(
    async (data: FormDTO) => {
      try {
        setLoadingRequest(true);
        formRef.current?.setErrors({});
        await updateOneUser({
          variables: {
            data: {
              name: data.name.toUpperCase(),
              email: data.email,
              document: data.cpf,
              phone: data.phone,
              documentType: cpfOrCnpj,
              address: {
                update: {
                  zipcode: data.cep,
                  state: {
                    connect: {
                      id: data.state || initialData?.state,
                    },
                  },
                  city: {
                    connect: {
                      id: data.city || initialData?.city,
                    },
                  },
                  neighborhood: {
                    connect: {
                      id: data.neighborhood || initialData?.neighborhood,
                    },
                  },
                  street: data.street,
                  number: data.number,
                  geolocation: {
                    update: {
                      latitude: marker?.lat.toString(),
                      longitude: marker?.lng.toString(),
                    },
                  },
                },
              },
            },
            where: {
              id: Number(id),
            },
          },
        });

        addToast({
          type: 'success',
          title: 'Cadastro atualizado com sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique se todos os campos foram preenchidos.',
        });
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast, marker, updateOneUser, cpfOrCnpj, id, initialData],
  );

  const handleRecover = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoading(true);
        formPassRef.current?.setErrors({});
        const schema = Yup.object().shape({
          oldpassword: Yup.string().required('Senha obrigatória'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });
        const response = await changeUserPassword({
          variables: {
            data: {
              currentPassword: data.oldpassword,
              newPassword: data.password,
            },
          },
        });
        console.log(response.data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formPassRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Erro na atualização de senha',
          description: 'Verifique se todos os campos foram preenchidos.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, changeUserPassword],
  );

  const handleCenterChanged = useCallback(
    debounce(() => {
      if (mapRef.current) {
        const newPos = mapRef.current.getCenter().toJSON();
        setInitialPosition([newPos.lat, newPos.lng]);
      }
    }, 500),
    [],
  );

  if (loadError) return <h1>Error</h1>;
  if (!isLoaded || loading)
    return (
      <div style={{ margin: '100px auto' }}>
        <Loading />
      </div>
    );

  return (
    <>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginRight: '131px',
            }}
          >
            <Header>
              <img src={logoImg} alt="TaNaMao" />
              <strong>Editar empresa</strong>
            </Header>

            <FormCard>
              <MaskInput
                name="cep"
                label="CEP *"
                placeholder="Ex: 00000-000"
                mask="99999-999"
              />
              <Select
                name="state"
                label="Estado *"
                options={
                  states
                    ? states.map((state) => ({
                        label: get(state, 'name', ''),
                        value: get(state, 'id', ''),
                      }))
                    : []
                }
                placeholder="Selecione seu estado"
              />
              <Select
                name="city"
                label="Cidade *"
                placeholder="Selecione sua cidade"
                onChange={(event) => {
                  setCityFilter(get(event, 'value', null));
                  formRef.current?.clearField('neighborhood');
                }}
                options={
                  cities
                    ? cities.map((city) => ({
                        label: get(city, 'name', ''),
                        value: get(city, 'id', ''),
                      }))
                    : []
                }
              />
              <Select
                name="neighborhood"
                label="Bairro *"
                placeholder="Digite seu bairro"
                options={
                  neighborhoods
                    ? neighborhoods
                        .filter((c) => cityFilter === get(c, 'cityId', null))
                        .map((neighborhood) => ({
                          label: get(neighborhood, 'name', ''),
                          value: get(neighborhood, 'id', ''),
                        }))
                    : []
                }
              />
              <GenericInput
                name="street"
                label="Rua *"
                placeholder="Digite sua rua"
              />
              <GenericInput
                name="number"
                label="Número *"
                placeholder="Digite o número do endereço"
              />
              <GenericInput
                name="complement"
                label="Complemento"
                placeholder="Digite o complemento"
              />
              <Button onClick={() => setOpen(true)}>Confirmar Endereço</Button>
            </FormCard>
          </div>

          <FormCard>
            <RadioGroup
              value={cpfOrCnpj}
              onChange={(value) => {
                setCpfOrCnpj(value.target.value);
              }}
              row
            >
              <FormControlLabel
                value="CPF"
                control={<Radio color="default" />}
                label={
                  <Typography style={{ color: '#49717a' }}>CPF</Typography>
                }
              />
              <FormControlLabel
                value="CNPJ"
                control={<Radio color="default" />}
                label={
                  <Typography style={{ color: '#49717a' }}>CNPJ</Typography>
                }
              />
            </RadioGroup>
            <MaskInput
              name="cpf"
              label={`${cpfOrCnpj} *`}
              placeholder={`Ex: ${
                cpfOrCnpj === 'CPF' ? '000.000.000-00' : '00.000.000/0000-00'
              }`}
              mask={
                cpfOrCnpj === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99'
              }
            />
            <GenericInput
              name="name"
              label="Nome completo *"
              placeholder="Selecione seu nome"
            />
            <MaskInput
              name="phone"
              label="Celular *"
              placeholder="Ex: (00) 00000-0000"
              mask="(99) 99999-9999"
            />
            <GenericInput
              name="email"
              label="E-mail *"
              placeholder="Digite seu e-mail"
            />

            <Button
              type="submit"
              disabled={loadingRequest}
              style={{ marginBottom: 20 }}
            >
              {loadingRequest ? 'Enviando...' : `Atualizar Cadastro`}
            </Button>
            {user.role === 'ADMIN' && (
              <Form
                ref={formPassRef}
                onSubmit={handleRecover}
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  width: '100%',
                  padding: 0,
                }}
              >
                <GenericInput
                  name="oldpassword"
                  label="Senha *"
                  type="password"
                  placeholder="Digite sua senha"
                />
                <GenericInput
                  name="password"
                  label="Nova Senha *"
                  type="password"
                  placeholder="Nova Senha"
                />
                <Button
                  disabled={loadingRequest}
                  onClick={() => formPassRef.current?.submitForm()}
                >
                  {loadingRequest ? 'Enviando...' : `Atualizar Senha`}
                </Button>
              </Form>
            )}
          </FormCard>
        </Form>
      </Container>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
        PaperProps={{ style: { maxWidth: '100%' } }}
      >
        <GoogleMap
          onLoad={(map) => {
            mapRef.current = map;
          }}
          onCenterChanged={handleCenterChanged}
          mapContainerStyle={{ width: '90vw', height: '100vh' }}
          zoom={18}
          center={{ lat: initialPosition[0], lng: initialPosition[1] }}
          onClick={onMapClick}
        >
          {marker && (
            <Marker
              key={`${marker.lat}-${marker.lng}`}
              position={{ lat: marker.lat, lng: marker.lng }}
            />
          )}
        </GoogleMap>
        {marker ? (
          <Button
            style={{
              width: '20%',
              position: 'absolute',
              right: '40%',
              top: '90%',
            }}
            onClick={() => setOpen(false)}
          >
            Confirmar
          </Button>
        ) : null}
      </Dialog>
    </>
  );
};

export default EditUser;
