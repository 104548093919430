import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
  StylesConfig,
  Theme,
} from 'react-select';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, Content, Error } from './styles';

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  label?: string;
}
const Select: React.FC<Props> = ({ name, label, ...rest }) => {
  const selectRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const Styles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      border: 0,
      display: 'flex',
      flex: 1,
      margin: 0,
      padding: 0,
      boxShadow: '0',
      background: 'transparent',
    }),
    option: (styles) => ({
      ...styles,
      color: 'black',
    }),
    container: (styles) => ({
      ...styles,
      flex: 1,
      background: 'transparent',
      border: 0,
      borderColor: '#fff',
      padding: 0,
      margin: 0,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: 3,
      color: '#436461',
    }),
    indicatorSeparator: () => ({
      width: 0,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: '#436461',
    }),
  };

  const themeProps = (theme: Theme): Theme => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#436461',
        neutral0: '#fff',
        primary25: '#30D2C3',
        primary50: '#30D2C3',
        neutral80: '#436461',
        neutral30: '#30D2C3',
      },
    };
  };

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container>
      {label && <strong>{label}</strong>}
      <Content isFocused={isFocused} isErrored={!!error}>
        <ReactSelect
          defaultValue={defaultValue}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={selectRef}
          classNamePrefix="react-select"
          styles={Styles}
          theme={themeProps}
          maxMenuHeight={250}
          {...rest}
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Content>
    </Container>
  );
};
export default Select;
