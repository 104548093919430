import React from 'react';

import { Dialog } from '@material-ui/core';
import { Container, ContainerRow } from './styles';

interface DeliverymenItem {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
  cpf: string;
  city: {
    name: string;
  };
  state: {
    name: string;
  };
  documentUrl: string;
  phone: string;
  proofOfAddressUrl: string;
  accountStatus: string;
  veniche: {
    type: string;
    plate: string;
    brand: string;
    model: string;
    color: string;
    owner: {
      name: string;
    };
    crlvUrl: string;
  };
  createdAt: string;
  deliverymanEvaluations?: {
    user?: {
      name?: string;
    };
    comment: string;
    rating: number;
  }[];
}

interface StateModalProps {
  setOpen: any;
  open: boolean;
  item: DeliverymenItem;
}

const EvaluationModal: React.FC<StateModalProps> = ({
  setOpen,
  open,
  item,
}) => {
  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{ style: { maxWidth: '100%' } }}
    >
      <Container>
        <span>Nome: {item.name}</span>

        {item.deliverymanEvaluations?.length === 0 ? (
          <span style={{ marginTop: 20, textAlign: 'center' }}>
            Não existem avaliações
          </span>
        ) : (
          <ContainerRow style={{ marginTop: 20, marginBottom: 10 }}>
            <span>Empresa</span>
            <span>Nota</span>
          </ContainerRow>
        )}

        {item.deliverymanEvaluations?.map((evaluetion) => (
          <ContainerRow>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{evaluetion?.user?.name}</span>
              {evaluetion?.comment !== '' && (
                <span style={{ fontSize: 12 }}>
                  Comentário: {evaluetion?.comment}
                </span>
              )}
            </div>
            <span>{evaluetion?.rating}</span>
          </ContainerRow>
        ))}
      </Container>
    </Dialog>
  );
};

export default EvaluationModal;
