import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
// import { FiBell } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';

import logoImg from '../../assets/logo.svg';

import {
  Container,
  Content,
  NavBar,
  Profile,
  ProfileContainer,
} from './styles';

interface UserInfo {
  name: string;
  role: string;
}

const Header: React.FC = () => {
  const { user, signOut } = useAuth();
  const [userInfo] = useState(user as UserInfo);

  return (
    <Container>
      <Content>
        <nav>
          <span>
            <img src={logoImg} alt="TaNaMaoLogo" />
          </span>
          <NavBar>
            <NavLink to="/orders">ENTREGAS</NavLink>
            {/* <NavLink to="/statistics">ESTATÍSTICAS</NavLink> */}
            <NavLink to="/extract">EXTRATO</NavLink>
            {userInfo.role !== 'DEFAULT' ? (
              <>
                <NavLink to="/users">USUÁRIOS</NavLink>
                <NavLink to="/deliverymen">ENTREGADORES</NavLink>
              </>
            ) : null}

            {userInfo.role === 'ADMIN' && (
              <>
                <NavLink to="/deliveryFees">TAXAS DE ENTREGA</NavLink>
                <NavLink to="/cities">CIDADES</NavLink>
              </>
            )}

          </NavBar>
        </nav>
        <ProfileContainer>
          {/* <FiBell size={20} /> */}
          <Profile>
            <strong>{userInfo.name}</strong>
            <button type="button" onClick={signOut}>
              sair
            </button>
          </Profile>
          <span>{userInfo.name[0]}</span>
        </ProfileContainer>
      </Content>
    </Container>
  );
};

export default Header;
