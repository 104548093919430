import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import Orders from '../pages/Orders';
import Statistics from '../pages/Statistics';
import Extract from '../pages/Extract';
import Users from '../pages/Users';
import Deliverymen from '../pages/Deliverymen';
import DeliveryFees from '../pages/DeliveryFees';
import Cities from '../pages/Cities';
import EditUser from '../pages/EditUser';
import EditDeliveryman from '../pages/EditDeliveryman';
import RecoverPassword from '../pages/RecoverPassword';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/recoverPassword" component={RecoverPassword} />

    <Route path="/orders" component={Orders} isPrivate />
    <Route path="/statistics" component={Statistics} isPrivate />
    <Route path="/extract" component={Extract} isPrivate />
    <Route path="/users" component={Users} isPrivate />
    <Route path="/userEdit/:id" component={EditUser} isPrivate />
    <Route path="/deliverymanEdit/:id" component={EditDeliveryman} isPrivate />
    <Route path="/deliverymen" component={Deliverymen} isPrivate />
    <Route path="/cities" component={Cities} isPrivate />
    <Route path="/deliveryFees" component={DeliveryFees} isPrivate />
  </Switch>
);

export default Routes;
