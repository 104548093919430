import React, { useRef, useState, useCallback, useEffect } from 'react';

// import { Container } from './styles';
import { debounce } from 'lodash';
import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Dialog,
  TextField,
} from '@material-ui/core';
import { ColorButton, Container, ContainerRow } from './styles';

interface Neighborhood {
  id: string;
  name: string;
}

interface CitiesModalProps {
  priceDeliveryFee?: number;
  neighborhoodDeliveryFee?: string;
  setOpen: any;
  open: boolean;
  neighborhoods: Neighborhood[];
  handleDeliveryFee: (price: number, neighborhoodId: string) => Promise<void>;
}

const DeliveryFeeModal: React.FC<CitiesModalProps> = ({
  setOpen,
  open,
  neighborhoods,
  handleDeliveryFee,
  priceDeliveryFee = 0,
  neighborhoodDeliveryFee = '',
}) => {
  const mapRef = useRef<any>(null);
  const [neighborhood, setNeighborhood] = useState<string>(
    neighborhoodDeliveryFee,
  );
  const [price, setPrice] = useState<number>(priceDeliveryFee);

  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{ style: { maxWidth: '100%' } }}
    >
      <Container>
        <ContainerRow>
          <TextField
            inputProps={{ inputMode: 'numeric' }}
            style={{ flex: 1, marginRight: 20 }}
            variant="outlined"
            required
            fullWidth
            value={price}
            onChange={(e) => setPrice(parseInt(e.target.value))}
            label="Valor"
          />
        </ContainerRow>
        <ContainerRow>
          <FormControl variant="outlined" style={{ flex: 1 }}>
            <InputLabel id="demo-simple-select-filled-label">Bairro</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              onChange={(event: any) => {
                setNeighborhood(event.target.value);
              }}
              value={neighborhood}
            >
              {neighborhoods.map((item: Neighborhood) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ColorButton
            style={{
              width: '20%',
              position: 'absolute',
              right: '40%',
              top: '90%',
            }}
            onClick={() => {
              setPrice(0);
              setNeighborhood('');
              handleDeliveryFee(price, neighborhood);
            }}
          >
            Confirmar
          </ColorButton>
        </ContainerRow>
      </Container>
    </Dialog>
  );
};

export default DeliveryFeeModal;
