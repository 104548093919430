import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

export const ColorButton = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: '#49717A',
    '&:hover': {
      backgroundColor: '#334f55',
    },
    width: '100%',
  },
}))(Button);

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-self: center;
  margin: 20px;
`;

export const Container = styled.div`
  display: flex;
  padding: 30px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
