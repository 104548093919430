import React, {
  useEffect,
  createContext,
  useCallback,
  useState,
  useContext,
} from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useToast } from './toast';
import client from '../services/graphqlClient';

interface AuthState {
  token: string;
  user: {
    id: string;
    name: string;
    role: string;
    address?: {
      cityId: string;
      geolocation: {
        latitude: string;
        longitude: string;
      };
    };
    accountStatus: string;
  };
  userId: string;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: {
    id: string;
    name: string;
    role: string;
    address?: {
      cityId: string;
      geolocation: {
        latitude: string;
        longitude: string;
      };
    };
    accountStatus: string;
  };
  userId: string;
  token: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  loadUser(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthenticateUserRequest = gql`
  mutation authenticateUser($data: AuthenticateUserInput!) {
    authenticateUser(data: $data) {
      user {
        id
        name
        role
        address {
          cityId
          geolocation {
            latitude
            longitude
          }
        }
      }
      token
    }
  }
`;

const GetUserRequest = gql`
  query user($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      name
      role
      address {
        cityId
        geolocation {
          latitude
          longitude
        }
      }
      accountStatus
    }
  }
`;

const AuthProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@TaNaMao:token');
    const user = localStorage.getItem('@TaNaMao:user');

    if (token && user) {
      return { token, user: JSON.parse(user), userId: JSON.parse(user).id };
    }

    return {} as AuthState;
  });
  const [authenticateUser] = useMutation(AuthenticateUserRequest);

  const signOut = useCallback(() => {
    localStorage.removeItem('@TaNaMao:token');
    localStorage.removeItem('@TaNaMao:user');

    setData({} as AuthState);
  }, []);

  const loadUser = useCallback(async () => {
    try {
      const { data: response } = await client.query({
        query: GetUserRequest,
        fetchPolicy: 'no-cache',
        variables: {
          where: {
            id: data.userId,
          },
        },
      });

      const { user } = response;

      localStorage.setItem('@TaNaMao:user', JSON.stringify(user));

      setData((prevState) => ({ ...prevState, user }));

      if (
        user.accountStatus === 'RECUSED' ||
        user.accountStatus === 'SUSPENDED'
      ) {
        signOut();
      }
    } catch (error) {
      console.log(error);
    }
  }, [data, signOut]);

  useEffect(() => {
    if (data.userId) {
      loadUser();
    }
  }, []); // eslint-disable-line

  const signIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        const response = await authenticateUser({
          variables: {
            data: {
              email,
              password,
              deviceId: '',
              deviceType: '',
            },
          },
        });

        const { user, token } = response.data.authenticateUser;

        localStorage.setItem('@TaNaMao:token', token);
        localStorage.setItem('@TaNaMao:user', JSON.stringify(user));

        setData({ token, user, userId: user.id });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [authenticateUser, addToast],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        userId: data.userId,
        signIn,
        signOut,
        loadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be user within  an AuthProviver');
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };
