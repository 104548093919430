import StringMask from 'string-mask';

export const applyPriceMask = (value, prefix = 'R$ ', suffix = '') => {
  let priceMask = new StringMask('#,##0.00', { reverse: true });
  if (value) {
    if (typeof value === 'number') {
      value = value.toString();
    }
    value = value.replace(/\D/g, '');
    value = parseFloat(value);

    if (value.length > 5) {
      priceMask = new StringMask('##0,00', { reverse: true });
    } else {
      priceMask = new StringMask('#.##0,00', { reverse: true });
    }

    return prefix + (priceMask.apply(value) || 0) + suffix;
  }

  return prefix + value + suffix;
};
