import React, { useState, useEffect, useCallback } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery, useSubscription } from '@apollo/react-hooks';
import { startOfToday } from 'date-fns';
import { useAuth } from '../../hooks/auth';

import OrderCard from './OrderCard';
import NewOrderForm from './NewOrderForm';
import Loading from '../../components/Loading';
import { MapOrderModal } from '../../components';
import { Container, Content, List, ColorButton } from './styles';

const GetUserDeliveriesRequest = gql`
  query($where: DeliveryOrderWhereInput) {
    deliveryOrders(where: $where, orderBy: { createdAt: desc }) {
      id
      status
      user {
        name
      }
      deliveryman {
        name
      }
      deliveries {
        id
        status
        neighborhood {
          name
        }
        price
      }
      createdAt
      updatedAt
      deliverymanEvaluation {
        rating
      }
    }
  }
`;

const deliveryOrdersUpdatesRequest = gql`
  subscription deliveryOrdersUpdates($userId: Int!, $role: String) {
    deliveryOrdersUpdates(userId: $userId, role: $role) {
      id
      status
      user {
        name
      }
      deliveryman {
        name
      }
      deliveries {
        id
        status
        neighborhood {
          name
        }
        price
      }
      createdAt
      updatedAt
      deliverymanEvaluation {
        rating
      }
    }
  }
`;

interface Delivery {
  id: string;
  status: string;
  user: { name: string };
  deliveryman: { name: string };
  deliveries: {
    id: string;
    status: string;
    neighborhood: {
      name: string;
    };
    price: number;
  }[];
  createdAt: string;
  updatedAt: string;
  deliverymanEvaluation: {
    rating: number;
  };
}

interface UserInfo {
  id: string;
  role: string;
}

const Orders: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [openMap, setOpenMap] = useState(false);

  const [loading, setLoading] = useState(false);
  const [deliveries, setDeliveries] = useState<Delivery[]>([]);
  const { user, loadUser } = useAuth();
  const [userInfo] = useState(user as UserInfo);

  const [getDeliveries] = useLazyQuery(GetUserDeliveriesRequest, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        createdAt: {
          gte: startOfToday().toISOString(),
        },
      },
    },
    onCompleted: (response) => {
      setDeliveries(response.deliveryOrders);
      setLoading(false);
    },
  });

  useSubscription(deliveryOrdersUpdatesRequest, {
    variables: { userId: userInfo.id, role: userInfo.role },
    onSubscriptionData: ({
      subscriptionData: {
        data: { deliveryOrdersUpdates },
      },
    }) => {
      setDeliveries((state) => {
        if (state.some((item) => item.id === deliveryOrdersUpdates.id)) {
          return state.map((order: Delivery) =>
            order.id === deliveryOrdersUpdates.id
              ? deliveryOrdersUpdates
              : order,
          );
        }
        if (state) {
          return [...state, deliveryOrdersUpdates];
        }

        return deliveryOrdersUpdates;
      });
    },
  });

  useEffect(() => {
    loadUser();
    setLoading(true);
    getDeliveries();
  }, [getDeliveries]); // eslint-disable-line

  const updateOrder = useCallback((order: Delivery) => {
    setDeliveries((prevState) =>
      prevState.map((item) => (item.id === order.id ? order : item)),
    );
  }, []);

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <>
          <header>
            <strong>GERENCIAR ENTREGAS</strong>
            <div>
              {userInfo.role === 'DEFAULT' &&
                !(user.accountStatus === 'RECUSED') &&
                !(user.accountStatus === 'SUSPENDED') && (
                  <ColorButton
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setOpen(true);
                      loadUser();
                    }}
                  >
                    Nova entrega
                  </ColorButton>
                )}
              {userInfo.role !== 'DEFAULT' && (
                <ColorButton onClick={() => setOpenMap(true)}>
                  Visualizar Mapa
                </ColorButton>
              )}
            </div>
          </header>
          <Content>
            <List>
              <strong>PROCURANDO ENTREGADOR</strong>
              <div
                style={{
                  height: '1px',
                  background: '#C1C1C1',
                  marginTop: '15px',
                }}
              />
              {deliveries &&
                deliveries
                  .filter(
                    (obj: Delivery) =>
                      obj.status === 'SEARCHING' ||
                      obj.status === 'WAITING_RESPONSE',
                  )
                  .map((item: Delivery) => (
                    <OrderCard
                      key={item.id}
                      order={item}
                      updateOrder={updateOrder}
                    />
                  ))}
            </List>
            <List>
              <strong>EM COLETA</strong>
              <div
                style={{
                  height: '1px',
                  background: '#C1C1C1',
                  marginTop: '15px',
                }}
              />
              {deliveries &&
                deliveries
                  .filter((obj: Delivery) => obj.status === 'WAITING_COLLECT')
                  .map((item: Delivery) => (
                    <OrderCard
                      key={item.id}
                      order={item}
                      updateOrder={updateOrder}
                    />
                  ))}
            </List>
            <List>
              <strong>ENTREGANDO</strong>
              <div
                style={{
                  height: '1px',
                  background: '#C1C1C1',
                  marginTop: '15px',
                }}
              />
              {deliveries &&
                deliveries
                  .filter((obj: Delivery) => obj.status === 'IN_PROGRESS')
                  .map((item: Delivery) => (
                    <OrderCard
                      key={item.id}
                      order={item}
                      updateOrder={updateOrder}
                    />
                  ))}
            </List>
            <List>
              <strong>ENTREGUE</strong>
              <div
                style={{
                  height: '1px',
                  background: '#C1C1C1',
                  marginTop: '15px',
                }}
              />
              {deliveries &&
                deliveries
                  .filter((obj: Delivery) => obj.status === 'FINISHED')
                  .map((item: Delivery) => (
                    <OrderCard
                      key={item.id}
                      order={item}
                      updateOrder={updateOrder}
                    />
                  ))}
            </List>
            <List>
              <strong>CANCELADO</strong>
              <div
                style={{
                  height: '1px',
                  background: '#C1C1C1',
                  marginTop: '15px',
                }}
              />
              {deliveries &&
                deliveries
                  .filter((obj: Delivery) => obj.status === 'CANCELED')
                  .map((item: Delivery) => (
                    <OrderCard
                      key={item.id}
                      order={item}
                      updateOrder={updateOrder}
                    />
                  ))}
            </List>
          </Content>
          {userInfo.role === 'DEFAULT' && (
            <NewOrderForm open={open} setClose={() => setOpen(false)} />
          )}
          {userInfo.role !== 'DEFAULT' && (
            <MapOrderModal open={openMap} setOpen={setOpenMap} />
          )}
        </>
      )}
    </Container>
  );
};

export default Orders;
