import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { last, first, get } from 'lodash';
import { IconButton, Typography } from '@material-ui/core';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Loading from '../../components/Loading';
import { useAuth } from '../../hooks/auth';
import DeliverymenItem from './DeliverymenItem';
import GenericInput from '../../components/GenericInput';
import Button from '../../components/Button';
import Select from '../../components/Select';

import { Container, DeliverymenList, ItemTitle, ContainerRow } from './styles';
import client from '../../services/graphqlClient';

const GetDeliverymen = gql`
  query(
    $where: DeliverymanWhereInput
    $after: DeliverymanWhereUniqueInput
    $before: DeliverymanWhereUniqueInput
    $first: Int
    $last: Int
  ) {
    deliverymen(
      where: $where
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: { createdAt: desc }
    ) {
      id
      name
      email
      avatarUrl
      cpf
      city {
        name
      }
      state {
        name
      }
      documentUrl
      phone
      proofOfAddressUrl
      accountStatus
      veniche {
        type
        plate
        brand
        model
        color
        owner {
          name
        }
        crlvUrl
      }
      createdAt
      deliverymanEvaluations {
        user {
          name
        }
        comment
        rating
      }
    }
  }
`;

interface Deliveryman {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
  cpf: string;
  city: {
    name: string;
  };
  state: {
    name: string;
  };
  documentUrl: string;
  phone: string;
  proofOfAddressUrl: string;
  accountStatus: string;
  veniche: {
    type: string;
    plate: string;
    brand: string;
    model: string;
    color: string;
    owner: {
      name: string;
    };
    crlvUrl: string;
  };
  createdAt: string;
}

interface UserInfo {
  role: string;
}

const amountOrders = 20;

const Deliverymen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [deliverymen, setDeliverymen] = useState<Deliveryman[]>([]);
  const { user } = useAuth();
  const [userInfo] = useState(user as UserInfo);
  const [page, setPage] = useState(1);
  const [firstIndex, setFirstIndex] = useState<string | null>(null);
  const [lastIndex, setLastIndex] = useState<string | null>(null);
  const formRef = useRef<FormHandles>(null);

  const getDeliverymen = useCallback(async (dataParams = {}, pageIndex = 0) => {
    setLoading(true);

    const { data } = await client.query({
      query: GetDeliverymen,
      fetchPolicy: 'no-cache',
      variables: {
        ...dataParams,
      },
    });

    if (data.deliverymen.length) {
      setDeliverymen(data.deliverymen);

      const firstValue = get(first(data.deliverymen), 'id', null);
      const lastValue = get(last(data.deliverymen), 'id', null);

      setFirstIndex(firstValue);
      setLastIndex(lastValue);
      setPage((prevState) => prevState + pageIndex);
    }

    if (data.deliverymen.length === 0) {
      setDeliverymen(data.deliverymen);
    }

    setLoading(false);
  }, []);

  const updateOneDeliveryman = useCallback(
    (id: string, newStatus: string) =>
      deliverymen.map((deliveryman: Deliveryman) =>
        deliveryman.id === id
          ? (deliveryman.accountStatus = newStatus)
          : deliveryman,
      ),
    [deliverymen],
  );

  const backPage = useCallback(() => {
    if (page > 1) {
      getDeliverymen({ before: { id: firstIndex }, last: amountOrders }, -1);
    }
  }, [page, getDeliverymen, firstIndex]);

  const nextPage = useCallback(() => {
    if (deliverymen.length === amountOrders && lastIndex) {
      getDeliverymen({ after: { id: lastIndex }, first: amountOrders }, 1);
    }
  }, [deliverymen, getDeliverymen, lastIndex]);

  useEffect(() => {
    setLoading(true);
    getDeliverymen({ first: amountOrders });
  }, [getDeliverymen]);

  const handleSubmit = async (data: any): Promise<void> => {
    try {
      let body = {};

      if (data.name !== '') {
        body = { name: { contains: data.name.toUpperCase() } };
      }

      if (data.accountStatus !== '') {
        body = { ...body, accountStatus: data.accountStatus };
      }

      getDeliverymen({ where: { ...body }, first: amountOrders });
    } catch (err) {
      console.log('s');
    }
  };

  const ColorTypography = withStyles({
    root: {
      color: '#334f55',
    },
  })(Typography);

  return (
    <>
      {userInfo.role === 'DEFAULT' ? (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      ) : (
        <Container>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <div style={{ width: '15vw' }}>
                  <GenericInput name="name" placeholder="Buscar pelo nome" />
                </div>
                <div style={{ width: '15vw', marginTop: 10 }}>
                  <Select
                    name="accountStatus"
                    placeholder="Status"
                    options={[
                      {
                        label: 'PENDING',
                        value: 'PENDING',
                      },
                      {
                        label: 'APPROVED',
                        value: 'APPROVED',
                      },
                      {
                        label: 'RECUSED',
                        value: 'RECUSED',
                      },
                      {
                        label: 'SUSPENDED',
                        value: 'SUSPENDED',
                      },
                    ]}
                  />
                </div>

                <Button style={{ width: '15vw', height: 40 }} type="submit">
                  <strong>Pesquisar</strong>
                </Button>
              </Form>
              <DeliverymenList>
                <thead>
                  <ItemTitle>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Tipo</th>
                    <th>Ações</th>
                  </ItemTitle>
                </thead>
                <tbody>
                  {deliverymen.map((item) => (
                    <DeliverymenItem
                      key={item.id}
                      item={item}
                      update={updateOneDeliveryman}
                    />
                  ))}
                </tbody>
              </DeliverymenList>
              <ContainerRow
                style={{ justifyContent: 'flex-end', alignItems: 'center' }}
              >
                <IconButton
                  aria-label="delete"
                  onClick={backPage}
                  disabled={loading}
                >
                  <NavigateBefore />
                </IconButton>
                <ColorTypography variant="h6" color="secondary">
                  {page}
                </ColorTypography>
                <IconButton
                  aria-label="delete"
                  onClick={nextPage}
                  disabled={loading}
                >
                  <NavigateNext />
                </IconButton>
              </ContainerRow>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default Deliverymen;
