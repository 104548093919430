import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-width: 90%;
  padding: 15px 0;
  margin: 0 auto;
  flex-direction: column;
`;

export const UserList = styled.table`
  margin-top: 22px;
  border-collapse: collapse;
  width: 100%;
`;

export const ItemTitle = styled.tr`
  th:last-child {
    padding-right: 13px;
    text-align: right;
  }
  th:first-child {
    padding-left: 13px;
  }
  th {
    font-size: 1rem;
    text-align: left;
    padding-bottom: 14px;
  }
`;
export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
`;
