import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import gql from 'graphql-tag';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { get, debounce } from 'lodash';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import getValidationErrors from '../../utils/getValidationErrors';
import logoImg from '../../assets/logo.svg';
import { useToast } from '../../hooks/toast';

import Loading from '../../components/Loading';
import GenericInput from '../../components/GenericInput';
import MaskInput from '../../components/MaskInput';
import Select from '../../components/Select';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';

import { Container, Header, FormCard } from './styles';

const CreateUserRequest = gql`
  mutation createUser($data: CreateUserInput!) {
    createUser(data: $data) {
      user {
        id
      }
    }
  }
`;

const getStateRequest = gql`
  query getSate {
    states {
      id
      name
    }

    cities {
      id
      name
    }

    neighborhoods {
      id
      name
      cityId
    }
  }
`;

interface MarkerObj {
  lat: number;
  lng: number;
}

interface FormDTO {
  cep: string;
  state: {
    name: string;
  };
  city: {
    name: string;
  };
  neighborhood: {
    name: string;
  };
  street: string;
  number: string;
  complement: string;
  cpf: string;
  name: string;
  phone: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

const libraries = ['places'];

const SignUp: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const mapRef = useRef<any>(null);
  const [cpfOrCnpj, setCpfOrCnpj] = useState('CPF');

  const [checkAge, setCheckAge] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [initialPosition, setInitialPosition] = useState<[number, number]>([
    0,
    0,
  ]);
  const [createUserRequest] = useMutation(CreateUserRequest);
  const [marker, setMarker] = useState<MarkerObj | null>(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries,
  });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);

  const [cityFilter, setCityFilter] = useState(null);

  const onMapClick = useCallback((e) => {
    setMarker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }, []);

  const [getStates] = useLazyQuery(getStateRequest, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setStates(response.states);
      setCities(response.cities);
      setNeighborhoods(response.neighborhoods);
    },
  });

  useEffect(() => {
    getStates();
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;

      setInitialPosition([latitude, longitude]);
    });
  }, [getStates]);

  const handleSubmit = useCallback(
    async (data: FormDTO) => {
      try {
        setLoadingRequest(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cep: Yup.string().required('Cep é obrigatório'),
          state: Yup.string().required('Estado é obrigatório'),
          city: Yup.string().required('Cidade é obrigatório'),
          neighborhood: Yup.string().required('Bairro é obrigatório'),
          street: Yup.string().required('Rua é obrigatório'),
          number: Yup.string().required('Número é obrigatório'),
          complement: Yup.string(),
          cpf: Yup.string().required('CPF é obrigatório'),
          name: Yup.string().required('Nome é obrigatório'),
          phone: Yup.string().required('Celular é obrigatório'),
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(6, 'No minimo 6 digitos'),
          passwordConfirm: Yup.string()
            .required('Confirmar senha é obrigatório')
            .when('password', (password: string, field: Yup.StringSchema) =>
              password
                ? field.required('Verifique se as senhas são iguais')
                : field,
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!checkAge || !checkTerms) {
          throw Error('Você precisa aceitar os nossos termos');
        }

        await createUserRequest({
          variables: {
            data: {
              name: data.name.toUpperCase(),
              email: data.email,
              password: data.password,
              confirmPassword: data.passwordConfirm,
              document: data.cpf,
              phone: data.phone,
              documentType: cpfOrCnpj,
              address: {
                zipcode: data.cep,
                stateId: data.state,
                cityId: data.city,
                neighborhoodId: data.neighborhood,
                street: data.street,
                number: data.number,
                geolocation: {
                  latitude: marker?.lat.toString(),
                  longitude: marker?.lng.toString(),
                },
                complement: '',
              },
            },
          },
        });

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique se todos os campos foram preenchidos.',
        });
      } finally {
        setLoadingRequest(false);
      }
    },
    [
      checkTerms,
      checkAge,
      addToast,
      history,
      marker,
      createUserRequest,
      cpfOrCnpj,
    ],
  );

  const handleCenterChanged = useCallback(
    debounce(() => {
      if (mapRef.current) {
        const newPos = mapRef.current.getCenter().toJSON();
        setInitialPosition([newPos.lat, newPos.lng]);
      }
    }, 500),
    [],
  );

  if (loadError) return <h1>Error</h1>;
  if (!isLoaded)
    return (
      <div style={{ margin: '100px auto' }}>
        <Loading />
      </div>
    );

  return (
    <>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginRight: '131px',
            }}
          >
            <Header>
              <img src={logoImg} alt="TaNaMao" />
              <strong>Cadastre sua empresa</strong>
            </Header>

            <FormCard>
              <MaskInput
                name="cep"
                label="CEP *"
                placeholder="Ex: 00000-000"
                mask="99999-999"
              />
              <Select
                name="state"
                label="Estado *"
                options={
                  states
                    ? states.map((state) => ({
                        label: get(state, 'name', ''),
                        value: get(state, 'id', ''),
                      }))
                    : []
                }
                placeholder="Selecione seu estado"
              />
              <Select
                name="city"
                label="Cidade *"
                placeholder="Selecione sua cidade"
                onChange={(event) => {
                  setCityFilter(get(event, 'value', null));
                  formRef.current?.clearField('neighborhood');
                }}
                options={
                  cities
                    ? cities.map((city) => ({
                        label: get(city, 'name', ''),
                        value: get(city, 'id', ''),
                      }))
                    : []
                }
              />
              <Select
                name="neighborhood"
                label="Bairro *"
                placeholder="Digite seu bairro"
                options={
                  neighborhoods
                    ? neighborhoods
                        .filter((c) => cityFilter === get(c, 'cityId', null))
                        .map((neighborhood) => ({
                          label: get(neighborhood, 'name', ''),
                          value: get(neighborhood, 'id', ''),
                        }))
                    : []
                }
              />
              <GenericInput
                name="street"
                label="Rua *"
                placeholder="Digite sua rua"
              />
              <GenericInput
                name="number"
                label="Número *"
                placeholder="Digite o número do endereço"
              />
              <GenericInput
                name="complement"
                label="Complemento"
                placeholder="Digite o complemento"
              />
              <Button onClick={() => setOpen(true)}>Confirmar Endereço</Button>
            </FormCard>
          </div>

          <FormCard>
            <RadioGroup
              value={cpfOrCnpj}
              onChange={(value) => {
                setCpfOrCnpj(value.target.value);
              }}
              row
            >
              <FormControlLabel
                value="CPF"
                control={<Radio color="default" />}
                label={
                  <Typography style={{ color: '#49717a' }}>CPF</Typography>
                }
              />
              <FormControlLabel
                value="CNPJ"
                control={<Radio color="default" />}
                label={
                  <Typography style={{ color: '#49717a' }}>CNPJ</Typography>
                }
              />
            </RadioGroup>
            <MaskInput
              name="cpf"
              label={`${cpfOrCnpj} *`}
              placeholder={`Ex: ${
                cpfOrCnpj === 'CPF' ? '000.000.000-00' : '00.000.000/0000-00'
              }`}
              mask={
                cpfOrCnpj === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99'
              }
            />
            <GenericInput
              name="name"
              label="Nome completo *"
              placeholder="Selecione seu nome"
            />
            <MaskInput
              name="phone"
              label="Celular *"
              placeholder="Ex: (00) 00000-0000"
              mask="(99) 99999-9999"
            />
            <GenericInput
              name="email"
              label="E-mail *"
              placeholder="Digite seu e-mail"
            />
            <GenericInput
              name="password"
              label="Senha *"
              type="password"
              placeholder="Digite sua senha"
            />
            <GenericInput
              name="passwordConfirm"
              label="Confirmar senha *"
              type="password"
              placeholder="Confirme sua senha"
            />

            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '25px',
              }}
            >
              <Checkbox
                check={checkAge}
                onClick={() => {
                  setCheckAge(!checkAge);
                }}
              />
              <strong style={{ color: 'black' }}>
                Confirmo que sou maior de 18 anos
              </strong>
            </span>

            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
                marginBottom: '21px',
              }}
            >
              <Checkbox
                check={checkTerms}
                onClick={() => {
                  setCheckTerms(!checkTerms);
                }}
              />
              <strong
                style={{
                  color: 'black',
                }}
              >
                Aceito os Termos de Uso e Politica de Privacidade
              </strong>
            </span>

            <Button type="submit" disabled={loadingRequest}>
              {loadingRequest ? 'Enviando...' : 'Finalizar Cadastro'}
            </Button>

            <Link to="/">
              <FiArrowLeft />
              Voltar para o login
            </Link>
          </FormCard>
        </Form>
      </Container>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
        PaperProps={{ style: { maxWidth: '100%' } }}
      >
        <GoogleMap
          onLoad={(map) => {
            mapRef.current = map;
          }}
          onCenterChanged={handleCenterChanged}
          mapContainerStyle={{ width: '90vw', height: '100vh' }}
          zoom={18}
          center={{ lat: initialPosition[0], lng: initialPosition[1] }}
          onClick={onMapClick}
        >
          {marker && (
            <Marker
              key={`${marker.lat}-${marker.lng}`}
              position={{ lat: marker.lat, lng: marker.lng }}
            />
          )}
        </GoogleMap>
        {marker ? (
          <Button
            style={{
              width: '20%',
              position: 'absolute',
              right: '40%',
              top: '90%',
            }}
            onClick={() => setOpen(false)}
          >
            Confirmar
          </Button>
        ) : null}
      </Dialog>
    </>
  );
};

export default SignUp;
