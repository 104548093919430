import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { debounce } from 'lodash';
import { Dialog } from '@material-ui/core';
import find from 'lodash/find';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import client from '../../services/graphqlClient';
import IMAGE from './motocycle.png';
import ENTREGANDO from './entregando.png';
import VERMELHA from './vermelha.png';
import { useAuth } from '../../hooks/auth';
import { ContainerDetail, Title, TextStatus, Row } from './styles';
import MarkerInfo from './components/MarkerInfo';
import { GET_DELIVERYMEN_ORDERS } from '../../graphql/query';
import {
  UPDATE_ONE_DELIVERYMAN,
  CHANGE_DELIVERYMAN_STATUS,
} from '../../graphql/mutation';
import { Deliveryman } from '../../utils/interfaces';

const libraries = ['places'];

const deliverymanLocationUpdatesRequest = gql`
  subscription {
    deliverymanLocationUpdates {
      deliveryman {
        id
        status
      }
      newGeolocation {
        latitude
        longitude
      }
    }
  }
`;

interface DeliveryMenMapMarker {
  id: number;
  deliveryman: Deliveryman;
  order: any;
}

interface MapOrderModalProps {
  setOpen: any;
  open: boolean;
}

const MapOrderModal: React.FC<MapOrderModalProps> = ({ setOpen, open }) => {
  const mapRef = useRef<any>(null);
  const [deliveryMenMarker, setDeliveryMenMarker] = useState<
    DeliveryMenMapMarker[]
  >([]);
  const [markerInfo, setMarkerInfo] = useState<Deliveryman>({} as Deliveryman);
  const { user } = useAuth();
  const [initialPosition, setInitialPosition] = useState<[number, number]>([
    0,
    0,
  ]);

  const onlineAmount = useMemo(() => {
    const onlines = deliveryMenMarker.filter(
      (item) => item.deliveryman.status === 'ONLINE',
    );
    return onlines.length;
  }, [deliveryMenMarker]);

  const offlineAmount = useMemo(() => {
    const onlines = deliveryMenMarker.filter(
      (item) => item.deliveryman.status === 'OFFLINE',
    );
    return onlines.length;
  }, [deliveryMenMarker]);

  const deliveringAmount = useMemo(() => {
    const onlines = deliveryMenMarker.filter(
      (item) => item.deliveryman.status === 'DELIVERING',
    );
    return onlines.length;
  }, [deliveryMenMarker]);

  const recievingRequestAmount = useMemo(() => {
    const onlines = deliveryMenMarker.filter(
      (item) => item.deliveryman.status === 'RECIEVING_REQUEST',
    );
    return onlines.length;
  }, [deliveryMenMarker]);

  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries,
  });

  useSubscription(deliverymanLocationUpdatesRequest, {
    onSubscriptionData: ({
      subscriptionData: {
        data: { deliverymanLocationUpdates },
      },
    }) => {
      setDeliveryMenMarker((prevState) =>
        prevState.map((item) =>
          item.deliveryman.id === deliverymanLocationUpdates.deliveryman.id
            ? {
                ...item,
                deliveryman: {
                  ...item.deliveryman,
                  status: deliverymanLocationUpdates.deliveryman.status,
                  geolocation: deliverymanLocationUpdates.newGeolocation,
                },
              }
            : item,
        ),
      );
    },
  });

  const handleCenterChanged = useCallback(
    debounce(() => {
      if (mapRef.current) {
        const newPos = mapRef.current.getCenter().toJSON();
        setInitialPosition([newPos.lat, newPos.lng]);
      }
    }, 500),
    [],
  );

  const onOffline = useCallback(async (id) => {
    try {
      const { data } = await client.mutate({
        mutation: CHANGE_DELIVERYMAN_STATUS,
        variables: {
          data: { deliverymanId: id },
        },
        fetchPolicy: 'no-cache',
      });

      console.log(data);

      setDeliveryMenMarker((prevState) =>
        prevState.map((item) =>
          item.deliveryman.id ===
          data.changeDeliverymanStatusForOffline.deliveryman.id
            ? {
                ...item,
                deliveryman: {
                  ...item.deliveryman,
                  status:
                    data.changeDeliverymanStatusForOffline.deliveryman.status,
                },
              }
            : item,
        ),
      );
    } catch (error) {
      console.log('error');
    }
  }, []);

  const onBlockAccount = useCallback(async (id) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_ONE_DELIVERYMAN,
        variables: {
          where: {
            id,
          },
          data: {
            accountStatus: 'SUSPENDED',
          },
        },
        fetchPolicy: 'no-cache',
      });

      setDeliveryMenMarker((prevState) =>
        prevState.map((item) =>
          item.deliveryman.id === data.updateOneDeliveryman.id
            ? {
                ...item,
                deliveryman: {
                  ...item.deliveryman,
                  accountStatus: data.updateOneDeliveryman.accountStatus,
                },
              }
            : item,
        ),
      );
    } catch (error) {
      console.log('error');
    }
  }, []);

  const getDeliverymen = useCallback(async () => {
    try {
      const { data } = await client.query({
        query: GET_DELIVERYMEN_ORDERS,
        variables: {
          whereOrder: {
            OR: [{ status: 'WAITING_COLLECT' }, { status: 'IN_PROGRESS' }],
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (data.deliverymen.length) {
        const result = data.deliverymen.map((item: Deliveryman) => ({
          id: item.id,
          deliveryman: item,
          order:
            find(
              data.deliveryOrders,
              (order) => order.deliveryman.id === item.id,
            ) || null,
        }));
        setDeliveryMenMarker(result);
        console.log(result.filter((i: any) => i.order !== null));
      }
    } catch (error) {
      console.log('error');
    }
  }, []);

  useEffect(() => {
    if (open) {
      const latitude = user.address?.geolocation.latitude;
      const longitude = user.address?.geolocation.longitude;
      if (latitude && longitude) {
        setInitialPosition([Number(latitude), Number(longitude)]);
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude: lat, longitude: lng } = position.coords;
          setInitialPosition([lat, lng]);
        });
      }
      getDeliverymen();
    }
  }, [open]); // eslint-disable-line

  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{ style: { maxWidth: '100%' } }}
    >
      <GoogleMap
        onLoad={(map) => {
          mapRef.current = map;
        }}
        onCenterChanged={handleCenterChanged}
        mapContainerStyle={{ width: '95vw', height: '100vh' }}
        zoom={15}
        center={{ lat: initialPosition[0], lng: initialPosition[1] }}
        // onClick={onMapClick}
      >
        {deliveryMenMarker.length &&
          deliveryMenMarker
            .filter(
              (item) =>
                item.deliveryman.status === 'RECIEVING_REQUEST' ||
                item.deliveryman.status === 'ONLINE' ||
                item.deliveryman.status === 'DELIVERING',
            )
            .map((item, index) => (
              <Marker
                key={String(index)}
                position={{
                  lat: Number(item.deliveryman.geolocation.latitude),
                  lng: Number(item.deliveryman.geolocation.longitude),
                }}
                icon={{
                  url:
                    item.deliveryman.status === 'ONLINE'
                      ? IMAGE
                      : item.deliveryman.status === 'DELIVERING'
                      ? ENTREGANDO
                      : VERMELHA,
                }}
                onClick={() => {
                  if (markerInfo?.id === item.deliveryman.id) {
                    setMarkerInfo({} as Deliveryman);
                  } else {
                    setMarkerInfo(item.deliveryman);
                  }
                }}
              >
                {markerInfo?.id === item.deliveryman.id && (
                  <InfoWindow
                    onCloseClick={() => setMarkerInfo({} as Deliveryman)}
                  >
                    <MarkerInfo
                      onBlockAccount={onBlockAccount}
                      onOffline={onOffline}
                      deliveryManMapMarker={item}
                    />
                  </InfoWindow>
                )}
              </Marker>
            ))}
      </GoogleMap>
      <ContainerDetail elevation={3}>
        <Title>Entregadores</Title>
        <Row>
          <TextStatus style={{ color: '#30d2c3' }}>Disponíveis</TextStatus>
          <TextStatus style={{ color: '#30d2c3' }}>{onlineAmount}</TextStatus>
        </Row>
        <Row>
          <TextStatus style={{ color: '#DE3B3B' }}>
            Recebendo chamada
          </TextStatus>
          <TextStatus style={{ color: '#DE3B3B' }}>
            {recievingRequestAmount}
          </TextStatus>
        </Row>
        <Row>
          <TextStatus style={{ color: '#436461' }}>Entregando</TextStatus>
          <TextStatus style={{ color: '#436461' }}>
            {deliveringAmount}
          </TextStatus>
        </Row>
        <Row>
          <TextStatus style={{ color: '#c9c9c9' }}>Offline</TextStatus>
          <TextStatus style={{ color: '#c9c9c9' }}>{offlineAmount}</TextStatus>
        </Row>
      </ContainerDetail>
    </Dialog>
  );
};

export default MapOrderModal;
