import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  background: #fff;
  width: 100%;
  border: 1px solid #dddddd;
  padding: 0 100px;
  /* min-width: 980px; */
`;

export const Content = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  nav {
    span {
      background: #30d2c3;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      margin-right: 20px;
      padding: 3px;
      img {
        width: 40px;
        height: 40px;
      }
    }
    display: flex;
    align-items: center;
  }
`;

export const NavBar = styled.div`
  border-left: 1px solid #ddd;
  padding-left: 30px;
  height: 32px;
  display: flex;
  align-items: center;

  a {
    margin-right: 22px;
    font-size: 1rem;
    font-weight: 300;
    color: #436461;
    text-decoration: none;

    &.active {
      font-weight: bold;
      color: #436461;
    }
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: #436461;
    margin-right: 16px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    color: #fff;
    background: #436461;
  }
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 32px;
  border-left: 1px solid #ddd;
  padding-left: 18px;

  strong {
    color: black;
    font-size: 1rem;
  }

  button {
    background: none;
    border: none;
    color: #de3b3b;
    &:hover {
      color: ${darken(0.15, '#de3b3b')};
    }
  }
`;
