import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useLoadScript } from '@react-google-maps/api';
import gql from 'graphql-tag';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { get } from 'lodash';

import getValidationErrors from '../../utils/getValidationErrors';
import logoImg from '../../assets/logo.svg';
import { useToast } from '../../hooks/toast';
import { GET_DELIVERYMAN } from '../../graphql/query';
import Loading from '../../components/Loading';
import GenericInput from '../../components/GenericInput';
import MaskInput from '../../components/MaskInput';
import Select from '../../components/Select';
import Button from '../../components/Button';
import client from '../../services/graphqlClient';

import { Container, Header, FormCard } from './styles';

const UPDATE_DELIVERYMAN = gql`
  mutation updateOneDeliveryman(
    $data: DeliverymanUpdateInput!
    $where: DeliverymanWhereUniqueInput!
  ) {
    updateOneDeliveryman(data: $data, where: $where) {
      id
    }
  }
`;

const getStateRequest = gql`
  query getSate {
    states {
      id
      name
    }

    cities {
      id
      name
    }

    neighborhoods {
      id
      name
      cityId
    }
  }
`;

interface MarkerObj {
  lat: number;
  lng: number;
}

interface FormDTO {
  state: number;
  city: number;
  cpf: string;
  name: string;
  plate: string;
  brand: string;
  model: string;
  color: string;
  phone: string;
  email: string;
  password?: string;
  passwordConfirm?: string;
}

const libraries = ['places'];

const EditDeliveryman: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [loading, setLoading] = useState(false);

  const [updateOneDeliveryman] = useMutation(UPDATE_DELIVERYMAN);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries,
  });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [initialData, setInitialData] = useState<FormDTO>({} as FormDTO);

  const { id } = useParams<{ id: string }>();

  const [getStates] = useLazyQuery(getStateRequest, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setStates(response.states);
      setCities(response.cities);
    },
  });

  const getInitialData = useCallback(async () => {
    setLoading(true);
    const { data: response } = await client.query({
      query: GET_DELIVERYMAN,
      fetchPolicy: 'no-cache',
      variables: {
        where: {
          id: Number(id),
        },
      },
    });
    const { deliveryman } = response;

    if (deliveryman) {
      setInitialData({
        cpf: deliveryman.cpf,
        name: deliveryman.name,
        phone: deliveryman.phone,
        email: deliveryman.email,
        state: deliveryman.stateId,
        city: deliveryman.cityId,
        plate: deliveryman.veniche.plate,
        brand: deliveryman.veniche.brand,
        model: deliveryman.veniche.model,
        color: deliveryman.veniche.color,
      });
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getStates();
    getInitialData();
  }, [getStates, getInitialData]);

  const handleSubmit = useCallback(
    async (data: FormDTO) => {
      try {
        setLoadingRequest(true);
        formRef.current?.setErrors({});
        await updateOneDeliveryman({
          variables: {
            data: {
              name: data.name.toUpperCase(),
              email: data.email,
              cpf: data.cpf,
              phone: data.phone,
              state: {
                connect: {
                  id: data.state || initialData?.state,
                },
              },
              city: {
                connect: {
                  id: data.city || initialData?.city,
                },
              },
              veniche: {
                update: {
                  type: 'MOTOCYCLE',
                  plate: data.plate,
                  brand: data.brand,
                  model: data.model,
                  color: data.color,
                },
              },
            },
            where: {
              id: Number(id),
            },
          },
        });

        addToast({
          type: 'success',
          title: 'Cadastro atualizado com sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique se todos os campos foram preenchidos.',
        });
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast, updateOneDeliveryman, id, initialData],
  );

  if (loadError) return <h1>Error</h1>;
  if (!isLoaded || loading)
    return (
      <div style={{ margin: '100px auto' }}>
        <Loading />
      </div>
    );

  return (
    <>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginRight: '131px',
            }}
          >
            <Header>
              <img src={logoImg} alt="TaNaMao" />
              <strong>Editar entregador</strong>
            </Header>

            <FormCard>
              <MaskInput
                name="cpf"
                label="CPF *"
                placeholder={`Ex: ${'000.000.000-00'}`}
                mask="999.999.999-99"
              />
              <GenericInput
                name="name"
                label="Nome completo *"
                placeholder="Selecione seu nome"
              />
              <MaskInput
                name="phone"
                label="Celular *"
                placeholder="Ex: (00) 00000-0000"
                mask="(99) 99999-9999"
              />
              <GenericInput
                name="email"
                label="E-mail *"
                placeholder="Digite seu e-mail"
              />
              <GenericInput
                name="model"
                label="Modelo *"
                placeholder="Digite seu modelo"
              />
              <GenericInput
                name="brand"
                label="Marca *"
                placeholder="Digite a marca"
              />
              <GenericInput
                name="color"
                label="Cor *"
                placeholder="Digite a cor"
              />
              <GenericInput
                name="plate"
                label="Placa *"
                placeholder="Digite sua placa"
              />
            </FormCard>
          </div>

          <FormCard>
            <Select
              name="state"
              label="Estado *"
              options={
                states
                  ? states.map((state) => ({
                      label: get(state, 'name', ''),
                      value: get(state, 'id', ''),
                    }))
                  : []
              }
              placeholder="Selecione seu estado"
            />
            <Select
              name="city"
              label="Cidade *"
              placeholder="Selecione sua cidade"
              options={
                cities
                  ? cities.map((city) => ({
                      label: get(city, 'name', ''),
                      value: get(city, 'id', ''),
                    }))
                  : []
              }
            />

            <Button type="submit" disabled={loadingRequest}>
              {loadingRequest ? 'Enviando...' : `Atualizar Cadastro`}
            </Button>
          </FormCard>
        </Form>
      </Container>
    </>
  );
};

export default EditDeliveryman;
