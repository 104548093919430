import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Photo = styled.img`
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
`;

export const Info = styled.p`
  margin-bottom: 5px;
`;
