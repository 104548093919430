import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 3em;
`;

export const Item = styled.div`
  display: flex;
  margin-bottom: 6px;

  h1 {
    font-size: 1rem;
    color: #444444;
  }

  strong {
    font-size: 1rem;
    margin-left: 5px;
    color: #666666;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: gray;
  margin: 15px 0px;
  opacity: 0.4;
`;

export const Options = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    border: 0;
    background: none;
    transition: opacity 0.1s;

    &:hover {
      opacity: 0.7;
    }
  }
`;
