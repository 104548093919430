import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { IconButton, Typography } from '@material-ui/core';

import { last, first, get } from 'lodash';
import { useAuth } from '../../hooks/auth';
import Loading from '../../components/Loading';
import GenericInput from '../../components/GenericInput';
import Button from '../../components/Button';
import Select from '../../components/Select';
import UserItem from './UserItem';
import client from '../../services/graphqlClient';

import { Container, UserList, ItemTitle, ContainerRow } from './styles';

const GetUsers = gql`
  query(
    $where: UserWhereInput
    $after: UserWhereUniqueInput
    $before: UserWhereUniqueInput
    $first: Int
    $last: Int
  ) {
    users(
      where: $where
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: { createdAt: desc }
    ) {
      id
      name
      email
      avatarUrl
      phone
      documentType
      document
      role
      accountStatus
      address {
        zipcode
        city {
          name
        }
        state {
          name
        }
        neighborhood {
          name
        }
        street
        number
      }
      createdAt
      userEvaluations {
        deliveryman {
          name
        }
        comment
        rating
      }
    }
  }
`;

interface User {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
  phone: string;
  documentType: string;
  document: string;
  role: string;
  accountStatus: string;
  address: {
    zipcode: string;
    city: {
      name: string;
    };
    state: {
      name: string;
    };
    neighborhood: {
      name: string;
    };
    street: string;
    number: string;
  };
  createdAt: string;
}

interface UserInfo {
  role: string;
}

const amountOrders = 20;

const Users: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const { user } = useAuth();
  const [userInfo] = useState(user as UserInfo);
  const formRef = useRef<FormHandles>(null);

  const [page, setPage] = useState(1);
  const [firstIndex, setFirstIndex] = useState<string | null>(null);
  const [lastIndex, setLastIndex] = useState<string | null>(null);

  const getUsers = useCallback(async (dataParams = {}, pageIndex = 0) => {
    setLoading(true);
    try {
      const { data } = await client.query({
        query: GetUsers,
        fetchPolicy: 'no-cache',
        variables: {
          ...dataParams,
        },
      });

      if (data.users.length) {
        setUsers(data.users);

        const firstValue = get(first(data.users), 'id', null);
        const lastValue = get(last(data.users), 'id', null);

        setFirstIndex(firstValue);
        setLastIndex(lastValue);
        setPage((prevState) => prevState + pageIndex);
      }
      if (data.users.length === 0) {
        setUsers(data.users);
      }
    } catch (error) {
      console.log('errro');
    }

    setLoading(false);
  }, []);

  const updateOneUser = useCallback(
    (id: string, newStatus: string) => {
      users.map((item: User) =>
        item.id === id ? (item.accountStatus = newStatus) : item,
      );
    },
    [users],
  );

  const backPage = useCallback(() => {
    if (page > 1) {
      getUsers({ before: { id: firstIndex }, last: amountOrders }, -1);
    }
  }, [page, getUsers, firstIndex]);

  const nextPage = useCallback(() => {
    if (users.length === amountOrders && lastIndex) {
      getUsers({ after: { id: lastIndex }, first: amountOrders }, 1);
    }
  }, [users, getUsers, lastIndex]);

  useEffect(() => {
    setLoading(true);
    getUsers({ first: amountOrders });
  }, [getUsers]);

  const handleSubmit = async (data: any): Promise<void> => {
    try {
      let body = {};

      if (data.name !== '') {
        body = { name: { contains: data.name.toUpperCase() } };
      }

      if (data.accountStatus !== '') {
        body = { ...body, accountStatus: data.accountStatus };
      }

      getUsers({ where: { ...body }, first: amountOrders });
    } catch (err) {
      console.log(err);
    }
  };

  const ColorTypography = withStyles({
    root: {
      color: '#334f55',
    },
  })(Typography);

  return (
    <>
      {userInfo.role === 'DEFAULT' ? (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      ) : (
        <Container>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <div style={{ width: '15vw' }}>
                  <GenericInput name="name" placeholder="Buscar pelo nome" />
                </div>
                <div style={{ width: '15vw', marginTop: 10 }}>
                  <Select
                    name="accountStatus"
                    placeholder="Status"
                    options={[
                      {
                        label: 'PENDING',
                        value: 'PENDING',
                      },
                      {
                        label: 'APPROVED',
                        value: 'APPROVED',
                      },
                      {
                        label: 'RECUSED',
                        value: 'RECUSED',
                      },
                      {
                        label: 'SUSPENDED',
                        value: 'SUSPENDED',
                      },
                    ]}
                  />
                </div>

                <Button style={{ width: '15vw', height: 40 }} type="submit">
                  <strong>Pesquisar</strong>
                </Button>
              </Form>
              <UserList>
                <thead>
                  <ItemTitle>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Tipo</th>
                    <th>Ações</th>
                  </ItemTitle>
                </thead>
                <tbody>
                  {users.map((item) => (
                    <UserItem
                      key={item.id}
                      item={item}
                      update={updateOneUser}
                    />
                  ))}
                </tbody>
              </UserList>
              <ContainerRow
                style={{ justifyContent: 'flex-end', alignItems: 'center' }}
              >
                <IconButton
                  aria-label="delete"
                  onClick={backPage}
                  disabled={loading}
                >
                  <NavigateBefore />
                </IconButton>
                <ColorTypography variant="h6" color="secondary">
                  {page}
                </ColorTypography>
                <IconButton
                  aria-label="delete"
                  onClick={nextPage}
                  disabled={loading}
                >
                  <NavigateNext />
                </IconButton>
              </ContainerRow>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default Users;
